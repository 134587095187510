/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Upload, Row, Col, Tag, Popconfirm,
   Modal, DatePicker, Image, Typography, Breadcrumb, Progress, Empty, Spin
} from 'antd';
import { SearchOutlined, CloudUploadOutlined, SolutionOutlined, EyeOutlined, DeleteOutlined, CalendarOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
// import SimpleBarReact from "simplebar-react";
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import uuid from 'react-uuid';
import moment from 'moment';
// import { PDFReader } from 'reactjs-pdf-reader';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import { ReportCardsAwardsTypes, Levels } from '../../constants';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Option } = Select;

var g_term_name = "";
var g_modal_title = "";
var g_report_card_url = "";
var g_subdomain = "";

const { Text } = Typography;

export function ReportCards() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [sectionLoading, setSectionLoading] = useState(false);
   const [studentId, setStudentId] = useState(
      {
         studentid: null,
         reportid: null,
      }
   );
   const [term, setTerm] = useState(null);
   const [level, setLevel] = useState(null);
   const [section, setSection] = useState([]);
   // const [searchForm, setSearchForm] = useState({
   //    term: null,
   //    level: null,
   //    section: null,
   // });

   const [sectionList, setSectionList] = useState([]);
   const [reportCardListLoading, setReportCardListLoading] = useState(false);
   const [reportCardList, setReportCardList] = useState([]);
   // const [uploadFile, setUploadFile] = useState();
   const [modalVisible, setModalVisible] = useState(false);
   const [studentName, setStudentName] = useState('');
   const [viewingDate, setViewingDate] = useState('');
   const [reportLabel, setReportLabel] = useState('');

   const [reportCardsAwardsTypes, setReportCardAwardsTypes] = useState([]);
   const [levelList, setLevelList] = useState([]);

   const [percentage, setPercentage] = React.useState(0);
   const [isUploading, setIsUploading] = React.useState(false);

   const [modalSchedule, setModalSchedule] = useState(false);
   const [modalViewing, setModalViewing] = useState(false);

   const subdomain = localStorage.getItem("lms-subdomain");
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const dateFormat = 'MMM D, YYYY hh:mm a';
   const customFormat = value => `${value.format(dateFormat)}`;

   const [listLoading, setListLoading] = useState(false);
   const [selectedStudents, setSelectedStudents] = useState([]);
   const [allowList, setAllowList] = useState([]);

   // var subdomain = localStorage.getItem("lms-subdomain");

   const upload_option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded * 100) / total)
         setPercentage(p)
         //console.log(`${percentage} %`)
      }
   }

   const props = {
      beforeUpload: file => {

         // if (viewingDate !== null && viewingDate !== '') {
         const isPDF = file.type === 'application/pdf';
         const isPNG = file.type === 'image/png';
         const isJPG = file.type === 'image/jpeg';
         const isBMP = file.type === 'image/bmp';
         // const isDOC = file.type === 'application/msword' || file.type === 'application/vnd.ms-word';
         // const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
         // const isXLS = file.type === 'application/msexcel' || file.type === 'application/vnd.ms-excel';
         // const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
         // const isPPT = file.type === 'application/vnd.ms-powerpoint';
         // const isPPTX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';

         const fileValid = isPNG || isJPG || isBMP || isPDF
            // || isDOC || isDOCX || isXLS || isXLSX || isPPT || isPPTX
            ;

         // if (!fileValid) {
         //    message.error(`Only PDF files can be uploaded`);
         // }

         // handleUploadReportCard(file);

         if (fileValid) {
            const isLt100M = file.size / 1024 / 1024 <= 5;

            if (isLt100M) {
               handleUploadReportCard(file);
            } else {
               Modal.error({
                  title: 'Report Card',
                  content: 'File should be less than 5 MB',
                  centered: true
               });
            }

            // console.log(uploadFileList);
         } else {
            Modal.error({
               title: 'Report Card',
               content: 'You are not allowed to upload that file type',
               centered: true
            });
         }
         // }
         // else {
         //    Modal.error({
         //       title: 'Report Card',
         //       content: 'Please set the distribution date before uploading.',
         //       centered: true
         //    });
         // }

         return false;
      },
   };

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      getReportCardsAwardsType();
      getLevels();
   }, []);

   const getReportCardsAwardsType = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/getreportcardawardtypes?type=reportcard`).then((response) => {
         if (response.data) {
            const data = response.data;
            let reportCards = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               reportCards.push(obj);
            });

            setReportCardAwardsTypes(reportCards);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            let levels = [];

            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               levels.push(obj);
            });

            setLevelList(levels);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const ReportCardsListColumns = [
      {
         title: 'Last Name',
         key: 'last_name',
         dataIndex: 'last_name',
         sorter: (a, b) => { return a.last_name.localeCompare(b.last_name) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: false,
         },
         render: last_name => (
            <Tooltip placement="right" title={last_name}>
               <span style={{ cursor: "default" }}>
                  {/* {last_name} */}
                  {
                     last_name !== null && last_name !== ''
                        ?
                        last_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        <></>
                  }
               </span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'First Name',
         key: 'first_name',
         dataIndex: 'first_name',
         sorter: (a, b) => { return a.first_name.localeCompare(b.first_name) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: false,
         },
         render: first_name => (
            <Tooltip placement="right" title={first_name}>
               <span style={{ cursor: "default" }}>
                  {/* {first_name} */}
                  {
                     first_name !== null && first_name !== ''
                        ?
                        first_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                        :
                        <></>
                  }
               </span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Uploaded',
         key: 'uploaded',
         dataIndex: 'uploaded',
         sorter: (a, b) => { return a.uploaded.localeCompare(b.uploaded) },
         render: uploaded => (
            <Tag style={{ fontSize: 12 }} color={uploaded === 'No' ? 'red' : 'green'}>
               {uploaded}
            </Tag>
         ),
         width: '12%'
      },
      {
         title: 'Viewing Status',
         key: 'allow_viewing_desc',
         // sorter: (a, b) => { return a.allow_viewing_desc.localeCompare(b.allow_viewing_desc)},
         sorter: (a, b) => { return (a.allow_viewing_desc === null ? '' : a.allow_viewing_desc).localeCompare(b.allow_viewing_desc === null ? '' : b.allow_viewing_desc) },
         render: payload => {
            return (
               <>
                  {payload.allow_viewing_desc !== null
                     ?
                     <Tag style={{ fontSize: 12 }} color={payload.allow_viewing === 0 ? 'red' : payload.with_back_accounts === 1 ? 'red' : 'green'}>
                        {
                           payload.with_back_accounts === 1
                              ?
                              'Not Allowed (With Balance)'
                              :
                              payload.allow_viewing_desc
                        }
                     </Tag>
                     :
                     <></>
                  }
               </>
            );
         },
         width: '14%'
      },
      {
         title: 'Distribution Date',
         key: 'viewing_schedule',
         dataIndex: 'viewing_schedule',
         // sorter: (a, b) => { return a.viewing_schedule.localeCompare(b.viewing_schedule)},
         sorter: (a, b) => { return (a.viewing_schedule === null ? '' : a.viewing_schedule).localeCompare(b.viewing_schedule === null ? '' : b.viewing_schedule) },
         width: '24%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return (
               <>
                  <Space size="small">
                     <Upload {...props}
                        showUploadList={false}
                        accept=".pdf, .jpeg, .png, .bmp"
                     >
                        <Tooltip placement="top" title={payload.file_url === null || payload.file_url === '' ? 'Upload ' + reportLabel : 'Re-upload ' + reportLabel}>
                           <CloudUploadOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                              onClick={() => setStudentId({ studentid: payload.student_id, reportid: payload.report_card_upload_id })}
                           />
                        </Tooltip>
                     </Upload>
                     {payload.file_url !== null && payload.file_url !== "" &&
                        <>
                           <Tooltip placement="top" title='Set Student Viewing'>
                              {
                                 payload.allow_viewing === 0
                                    ?
                                    <Popconfirm
                                       title="Are you sure you want allow?"
                                       onConfirm={() => handleAllowViewing(payload.report_card_upload_id)}
                                       okText="Yes"
                                       cancelText="No"
                                    >
                                       <SolutionOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                    </Popconfirm>
                                    :
                                    <Popconfirm
                                       title="Are you sure you want disallow?"
                                       onConfirm={() => handleAllowViewing(payload.report_card_upload_id)}
                                       okText="Yes"
                                       cancelText="No"
                                    >
                                       <SolutionOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                                       />
                                    </Popconfirm>
                              }
                           </Tooltip>
                           <Tooltip placement="top" title={'Preview ' + reportLabel}>
                              <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                                 onClick={() => handlePreview((payload.first_name + ' ' + payload.last_name), payload.file_url)}
                              />
                           </Tooltip>
                           <Tooltip placement="top" title='Remove Report Card'>
                              <Popconfirm
                                 title={"Are you sure you want to delete the uploaded ?" + reportLabel}
                                 onConfirm={() => handleRemoveReportCard(payload.report_card_upload_id)}
                                 // onCancel={cancel}
                                 okText="Yes"
                                 cancelText="No">

                                 <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                              </Popconfirm>
                           </Tooltip>
                        </>
                     }

                  </Space>
               </>
            );
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   // const handleChange = (e) => {
   //    setSearchForm(prev => ({
   //       ...prev,
   //       [e.target.name]: e.target.value,
   //    }));

   //    if (e.target.name === 'level') {
   //       setSearchForm(prev => ({
   //          ...prev,
   //          section: null,
   //       }));

   //       setSectionList([]);
   //       setSectionLoading(true);

   //       axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${e.target.value}`).then((response) => {
   //          if (response.data) {
   //             const data = response.data;

   //             var sectionlist = [];
   //             data.map((item) => {
   //                let obj = { 'value': item.value, 'name': item.name }
   //                sectionlist.push(obj);
   //             });

   //             setSectionList(sectionlist);
   //             setSectionLoading(false);
   //          }
   //       }, (error) => {
   //          setSectionLoading(false);
   //          console.log(error.message);
   //       });
   //    }
   // }

   const handleTermChange = (val) => {
      setReportLabel(GetReportTypeName(val));
      setTerm(val);
      // setReeportCardList([]);
   }

   const handleLevelChange = (val) => {
      setSection(null)
      setSectionList([]);
      // setReeportCardList([]);
      setLevel(val);
      setSectionLoading(true);

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
         if (response.data) {
            const data = response.data;

            var sectionlist = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               sectionlist.push(obj);
            });

            setSectionList(sectionlist);
            setSectionLoading(false);
         }
      }, (error) => {
         setSectionLoading(false);
         console.log(error.message);
      });
   }

   const handleSectionChange = (val) => {
      setSection(val);
      // setReeportCardList([]);
   }

   const handleSearchUploadList = () => {
      setReportCardListLoading(true);

      var url = `${process.env.REACT_APP_API_REPORT_CARD_UPLOAD}/reportcardlist?term=${term}&level=${level}&section=${section}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            setReportCardList(data);
            setReportCardListLoading(false);

            //-- Select only students with report cards
            let selectedStudents = [];
            var viewSched = '';

            for (var i = 0; i < data.length; i++) {
               data[i].key = data[i].student_id;

               if (data[i].allow_viewing === 1) {
                  selectedStudents.push(data[i].student_id);
                  viewSched = moment(data[i].viewing_schedule).format("YYYY-MM-DD HH:mm:ss");
               }
            }

            var allow = data.filter(p => p.report_card_upload_id !== null);
            setAllowList(allow);
            setViewingDate(viewSched);
            setSelectedStudents(selectedStudents);
         }
      }, (error) => {
         setReportCardListLoading(false);
         console.log(error.message);
      });
   }

   const handleUploadReportCard = (file) => {
      const formData = new FormData();
      formData.append('file', file);

      setPercentage(0);
      setIsUploading(true);

      const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMasteryFile?s3path=${process.env.REACT_APP_S3_REPORT_CARDS_PATH}`;
      axios.post(url, formData, upload_option).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;

            var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
            var report_card_upload_id = "report_card_upload_" + o2o_mode + "_" + uuid();

            // var upsert_data = {
            //    Report_card_upload_id: studentId.reportid === null ? report_card_upload_id : studentId.reportid,
            //    Student_id: studentId.studentid,
            //    Term: term,
            //    Level: level,
            //    Section: section,
            //    File_url: data,
            //    Uploader_id: user_id,
            //    Viewing_schedule: moment(viewingDate).format("YYYY-MM-DD HH:mm:ss"),
            // }

            var upsert_data = {
               Report_card_upload_id: studentId.reportid === null ? report_card_upload_id : studentId.reportid,
               Student_id: studentId.studentid,
               Term: term,
               Level: level,
               Section: section,
               File_url: data,
               Uploader_id: user_id,
            }

            var url = `${process.env.REACT_APP_API_REPORT_CARD_UPLOAD}/upsert`;
            axios.post(url, upsert_data).then((response) => {
               if (response.data) {
                  const data = response.data;

                  handleSearchUploadList();
                  setIsUploading(false);
               }
            }, (error) => {
               setReportCardListLoading(false);
               setIsUploading(false);
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
         setIsUploading(false);
      });
   }

   const handleAllowViewing = (report_card_upload_id) => {
      const url = `${process.env.REACT_APP_API_REPORT_CARD_UPLOAD}/setviewing?id=${report_card_upload_id}`;
      axios.post(url).then(response => {
         if (response.data) {
            const data = response.data;

            handleSearchUploadList();
         }
      }, (error) => {
         console.log(error.message);
         // setTimeout(key, 100);
      });
   }

   const handleRemoveReportCard = (id) => {
      const url = `${process.env.REACT_APP_API_REPORT_CARD_UPLOAD}/remove?id=${id}`;
      axios.post(url).then(response => {
         const data = response.data;
         handleSearchUploadList();
      }, (error) => {
         console.log(error.message);
         // setTimeout(key, 100);
      });
   }

   const handlePreview = (student_name, report_card_url) => {
      g_modal_title = student_name;
      g_report_card_url = report_card_url;
      g_subdomain = localStorage.getItem("lms-subdomain");
      setModalVisible(true);
   }

   const renderPreviewer = (document) => {
      if (document !== null && document !== '') {
         var re = /(?:\.([^.]+))?$/;
         var ext = re.exec(document.toLowerCase());

         var subdomain = localStorage.getItem("lms-subdomain");
         var url = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${document}`;

         switch (ext[1]) {
            case "pdf":
               return <>
                  {/* <RenderPDFReader url={url} height={"calc(100vh - 195px)"} /> */}
                  <div style={{ width: "100%", height: "calc(100vh - 195px)" }}>
                     <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                        <Viewer
                           pageLayout={{
                              transformSize: ({ size }) => ({
                                 height: size.height + 30,
                                 width: size.width + 30,
                              }),
                           }}
                           fileUrl={url}
                           defaultScale={SpecialZoomLevel.PageWidth}
                           initialPage={0}
                        />
                     </Worker>
                  </div>

                  {/* <object data={url} width="100%" style={{ height: 'calc(100vh - 195px)' }}>
                     <p>Brainee LMS PDF - View<a href={url}> PDF!</a></p>
                  </object> */}

                  {/* <RenderPDFViewer url={url} height={"calc(100vh - 185px)"} showdownload={false} showprint={false} showpagecontrols={true} showfullscreen={true} /> */}
               </>

            case "png": case "jpg": case "jpeg":
               return <>
                  <div
                     className={'modal-document-viewer'}
                     style={{ height: "calc(100vh - 195px)" }}
                  >
                     <Image
                        src={url}
                        // width={"100%"}
                        // height={"100%"}
                        style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
                     />
                  </div>
               </>
         }
      }
   }

   function GetReportTypeName(id) {
      for (var i = 0; i < reportCardsAwardsTypes.length; i++) {
         if (reportCardsAwardsTypes[i].value === id) {
            return reportCardsAwardsTypes[i].name;
         }
      }
   }

   const handleSetDistributionSchedule = () => {
      const url = `${process.env.REACT_APP_API_REPORT_CARD_UPLOAD}/setviewingschedule?term=${term}&level=${level}&section=${section}&schedule=${moment(viewingDate).format("YYYY-MM-DD HH:mm:ss")}`;
      axios.post(url).then(response => {
         if (response.data) {
            handleSearchUploadList();

            Modal.success({
               title: 'Report Card',
               content: 'Distribution schedule set successful.',
               centered: true
            });

            setModalSchedule(false);
         }
      }, (error) => {
         Modal.error({
            title: 'Report Card',
            content: 'Distribution schedule set failed.',
            centered: true
         });
      });
   }

   const allow_view_columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: 'Last Name',
         key: 'last_name',
         ...getColumnSearchProps('last_name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.last_name}>
               <Text>
                  {
                     payload.last_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                  }
               </Text>
            </Tooltip>
         ),
         width: '35%'
      },
      {
         title: 'First Name',
         key: 'first_name',
         ...getColumnSearchProps('first_name'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.first_name}>
               <Text>
                  {
                     payload.first_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                  }
               </Text>
            </Tooltip>
         ),
         width: '45%'
      },
      {
         title: 'Viewing',
         key: 'allow_viewing_desc',
         ...getColumnSearchProps('allow_viewing_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Text>
               {
                  payload.allow_viewing_desc
               }
            </Text>
         ),
         width: '20%'
      },
   ].filter(item => !item.hidden);

   const handleSetAllowViewingMultiple = () => {
      var selected = selectedStudents.join(',');

      const url = `${process.env.REACT_APP_API_REPORT_CARD_UPLOAD}/setviewingmultiple?term=${term}&level=${level}&section=${section}&selected=${selected}`;
      axios.post(url).then(response => {
         if (response.data) {
            handleSearchUploadList();

            Modal.success({
               title: 'Report Card',
               content: 'Allow viewing successful.',
               centered: true
            });

            setModalSchedule(false);
         }
      }, (error) => {
         Modal.error({
            title: 'Report Card',
            content: 'Allow viewing failed.',
            centered: true
         });
      });
   }

   const rowSelection = {
      selectedRowKeys: selectedStudents,
      onChange: (selectedRowKeys, selectedRows) => {
         setSelectedStudents(selectedRowKeys);
         props.setSelectedCallback(selectedRowKeys);
      },
   };

   const docs = [
      { uri: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${g_report_card_url}`, }
      // { uri: "../images/_images_pdf.pdf" },
      // {
      //    // uri: "../images/1234567.doc",
      //    
      //    // fileType: 'pdf',
      //    // fileName: 'demo.pdf'
      // },
   ];

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Report Card & Certificates</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Report Card</a></li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        {/* <HomeOutlined /> */}
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Report Card & Certificates</Breadcrumb.Item>
                     <Breadcrumb.Item>Report Card</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            <Row gutter={12} style={{ paddingBottom: "20px" }} >
               <Col span={24}>
                  <div className='search-columns-4'>
                     <Select
                        name='term'
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Type"
                        onChange={(val) => handleTermChange(val)}
                        // onChange={(e) => handleChange(e)}
                        value={term}
                     >
                        {reportCardsAwardsTypes.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>

                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Level"
                        onChange={(val) => handleLevelChange(val)}
                        value={level}
                     >
                        {levelList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>

                     <Select
                        loading={sectionLoading}
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Section"
                        value={section}
                        onChange={(val) => handleSectionChange(val)}
                     >
                        {sectionList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>

                     <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSearchUploadList()} icon={<SearchOutlined />}>Search</Button>
                  </div>
               </Col>
            </Row>

            {
               reportCardList.length > 0 &&
               <Row gutter={12} justify="left" align='middle' style={{ paddingBottom: "0px" }}>
                  <Col sm={24} md={12} lg={12} style={{ textAlign: "left" }}>
                     <Space>
                        <Button className='button-shadow' type='primary' shape='round' onClick={() => setModalSchedule(true)} icon={<CalendarOutlined />}>Set Distribution Schedule</Button>
                        <Button className='button-shadow' type='primary' shape='round' onClick={() => setModalViewing(true)} icon={<EyeInvisibleOutlined />}>Allow Viewing</Button>
                     </Space>
                  </Col>
                  <Col sm={24} md={12} lg={12} style={{ textAlign: "right" }}>
                     <Space>
                        <Text style={{ verticalAlign: "middle" }}>Schedule of Distribution: </Text>
                        <Text style={{ verticalAlign: "middle" }}><b>{viewingDate === '' ? 'Not Set' : moment(viewingDate).format('MMM. D, YYYY hh:mm a')}</b></Text>
                     </Space>
                  </Col>
               </Row>
            }


            <Row gutter={12} style={{ overflow: "auto" }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     {
                        reportCardListLoading
                           ?
                           <div className='center-empty-space'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                           :
                           (reportCardList.length > 0 && !reportCardListLoading)
                              ?
                              // <Table
                              //    rowKey={'uid'}
                              //    loading={reportCardListLoading}
                              //    size='small'
                              //    columns={ReportCardsListColumns}
                              //    dataSource={reportCardList}
                              //    pagination={{ position: ['topCenter'] }}
                              //    scroll={{
                              //       x: '1000px',
                              //       y: 'calc(100svh - 17.5rem)',
                              //    }}
                              //    footer={() => 'Total Records: ' + reportCardList.length}
                              // />
                              <Table
                                 rowKey={'student-list'}
                                 loading={reportCardListLoading}
                                 size='small'
                                 columns={ReportCardsListColumns}
                                 dataSource={reportCardList}
                                 pagination={false}
                                 scroll={{
                                    y: 'calc(100svh - 13.8rem)',
                                    x: '1000px'
                                 }}
                                 footer={() => 'Total Records: ' + reportCardList.length}
                              />
                              :
                              <div className='center-empty-space'>
                                 <Empty image="../images/brainee_mascot.png" description="No records found" />
                              </div>
                     }

                  </div>
               </Col>
            </Row>
         </div>

         <Modal
            key='preview-report-card'
            title={"Preview " + reportLabel + " for " + g_modal_title}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            width='75%'

            onCancel={() => { setModalVisible(false); }}
            onOk={() => { setModalVisible(false); }}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               <div style={{ width: "100%", height: "calc(100vh - 195px)" }}>
                  <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                     <Viewer
                        pageLayout={{
                           transformSize: ({ size }) => ({
                              height: size.height + 30,
                              width: size.width + 30,
                           }),
                        }}
                        fileUrl={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${g_report_card_url}`}
                        defaultScale={SpecialZoomLevel.PageWidth}
                        initialPage={0}
                     />
                  </Worker>
               </div>
            </div>
         </Modal>

         <Modal
            key="modal-upload"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Upload File</div>}
            destroyOnClose={true}
            centered
            open={isUploading}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <span>Uploading...</span>
            <Progress
               percent={percentage}
               status="active"
               strokeColor={{
                  '0%': '#5A98D2',
                  '100%': '#70D0D4',
               }}
            />
         </Modal>

         <Modal
            key="modal-schedule"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Schedule</div>}
            destroyOnClose={true}
            centered
            okText={"Set"}
            cancelText={"Close"}
            open={modalSchedule}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
            onOk={() => handleSetDistributionSchedule()}
            onCancel={() => setModalSchedule(false)}
         >
            <Text style={{ verticalAlign: "middle" }}>Schedule of Distribution</Text>
            <DatePicker
               placeholder="Select Date"
               showTime={{ format: 'hh:mm A', use12Hours: true }}
               format={customFormat}
               style={{ width: "100%" }}
               onBlur={(event) => setViewingDate(event.target.value)}
            // defaultValue={moment(viewingDate)}
            // onOk={(event) => setViewingDate(event.target.value)}
            />
         </Modal>

         <Modal
            key="modal-viewing"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Allow Viewing</div>}
            destroyOnClose={true}
            centered
            cancelText={"Close"}
            okText={"Save"}
            open={modalViewing}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
            onOk={() => handleSetAllowViewingMultiple()}
            onCancel={() => setModalViewing(false)}
         >
            <div className="dashboard-item-inner3">
               <Table
                  rowSelection={rowSelection}
                  loading={listLoading}
                  size='small'
                  columns={allow_view_columns}
                  dataSource={allowList}
                  pagination={false}
                  scroll={{
                     x: '450px',
                     y: 'calc(100vh - 18.5rem)',
                  }}
                  footer={() =>
                     <Row>
                        <Col span={12}>{'Total Records: ' + allowList.length}</Col>
                        <Col span={12} style={{ textAlign: 'right' }}>{'Allowed: ' + selectedStudents.length}</Col>
                     </Row>
                  }
               />
            </div>
         </Modal>
      </>
   );
}

