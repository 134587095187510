import React, { useState } from 'react'
import { Input, Tooltip } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';

import './ShortAnswer.css';

export default function ShortAnswerItem(props) {

   const [value, setValue] = useState('');

   const handleChangeValue = (value) => {
      setValue(value);
   }

   const handleRemoveOption = (idx) => {
      props.parentCallback("remove", "", idx);
   }

   const handleUpdateInput = () => {
      props.parentCallback("update-input", value, props.id);
   }

   return (
      <div onBlur={handleUpdateInput} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '7px' }}>
         <Input
            autoComplete='off'
            allowClear
            id={props.id}
            name={props.id}
            rows={1}
            defaultValue={props.correct}
            onChange={(event) => handleChangeValue(event.target.value)}
            disabled={props.mode.includes("assign") || props.mode.includes("view") ? true : false}
            style={{ borderRadius: 7, width: "100%", marginRight: '7px', fontSize: '16px' }}
         />
         {
            props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view")
               ?
               <></>
               :
               <Tooltip placement="right" title='Remove answer key'>
                  <CloseCircleTwoTone twoToneColor="red" style={{ cursor: "pointer", fontSize: "16px" }} onClick={() => handleRemoveOption(props.id)} />
               </Tooltip>
         }
      </div>
   )
}