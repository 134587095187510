/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Image, Radio, Form, Divider, Row, Col, Typography, Input, DatePicker, Space, Button, Modal, Popconfirm } from 'antd';
import { EyeOutlined, DownOutlined } from '@ant-design/icons';
import Utils from '../../misc/Utils';
import "./Application.scss"
import moment from 'moment';
import SectionedFormRenderer from '../../components/utility/SectionedFormRenderer';
import Enums from '../../misc/Enums';
import ProcessingModal from '../../components/processing/ProcessingModal';

const { Title } = Typography;

export function StudentProfile() {
   const [form] = Form.useForm();
   const navigate = useNavigate();
   const location = useLocation();

   const subdomain = localStorage.getItem("lms-subdomain");

   // const search = window.location.search;
   // const query = new URLSearchParams(search);
   // const application_id = query.get('id');

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);

   const [studentInfo, setStudentInfo] = useState(null);
   const [studentOtherInfo, setStudentOtherInfo] = useState(null);
   const [rc, setRC] = useState(null);
   const [bc, setBC] = useState(null);

   const [docToView, setDocToView] = useState(null);
   const [sectionedPIConfig, setSectionedPIConfig] = useState(null);
   const [updating, setUpdating] = useState(false);
   // const [forSubmit, setForSubmit] = useState(false);

   const [modalVisible, setModalVisible] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      getApplicationInfo(user_id);
   }, [user_id]);

   const getFormSectionedFields = (fn) => {
      var url = `${process.env.REACT_APP_API_CPH}/getsectionedfields?fn=${fn}&ft=full&m=edit`;
      /*console.log(url);*/
      axios.get(url)
         .then((response) => {
            if (response.data) {
               var data = response.data;
               setSectionedPIConfig(data);
               //console.log(data);
            }
         })
         .catch((error) => {
            // setLoading(false);
            console.log(error.message);
         });
   };

   const getApplicationInfo = (id) => {
      var url = `${process.env.REACT_APP_API_CPH}/studentprofile?id=${id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            let data = response.data;
            setStudentOtherInfo(JSON.parse(data.other_info));
            setStudentInfo(data);

            getFormSectionedFields(1);
            // setUpdating(false);
         }
      }, (error) => {
         // setLoading(false);
         console.log(error.message);
      });
   }

   const putFieldValues = (data) => {
      var fieldValues = JSON.parse(data.other_info);

      for (const sectionKey in sectionedPIConfig) {
         const section = sectionedPIConfig[sectionKey];
         for (const fieldKey in section.fields) {
            if (fieldKey.toLocaleLowerCase().includes("date")) {
               form.setFieldValue(fieldKey, moment(fieldValues[fieldKey], 'YYYY-MM-DD'));
            }
            else {
               form.setFieldValue(fieldKey, fieldValues[fieldKey]);
            }
         }
      }
   }

   const renderPreviewer = (document) => {
      return <>
         <div
            className={'modal-document-viewer'}
            style={{ height: "calc(100vh - 195px)" }}
         >
            <Image
               // src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${document}`}
               src={document}
               style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
            />
         </div>
      </>
   }

   const handleViewDoc = (doctoview) => {
      setDocToView(doctoview);
      setModalVisible(true);
   }

   const DoUpdateProfile = () => {
      setUpdating(true);

      const formData = form.getFieldsValue();
      var other_data = {};

      // Get the other data field values
      for (const sectionKey in sectionedPIConfig) {
         const section = sectionedPIConfig[sectionKey];

         for (const fieldKey in section.fields) {
            if (fieldKey.toLocaleLowerCase().includes("date")) {
               other_data[fieldKey] = moment(formData[fieldKey]).format('YYYY-MM-DD');
            } else {
               other_data[fieldKey] = formData[fieldKey];
            }
         }
      }

      var student_data = {
         lrn_no: formData.lrn,
         lastname: formData.last_name,
         firstname: formData.first_name,
         middlename: formData.middle_name,
         gender: formData.gender,
         dob: moment(formData.birth_date).format('YYYY-MM-DD'),
         email: formData.email_address,
         mobileno: formData.contact_number,
         other_info: JSON.stringify(other_data),
      }

      const url = `${process.env.REACT_APP_API_CPH}/updatestudentprofile?id=${user_id}`;
      axios.post(url, student_data).then(response => {
         console.log(response);

         getApplicationInfo(user_id);

         Modal.success({
            title: 'Brainee LMS',
            content: 'Profile updated.',
            centered: true,
            onOk: () => setUpdating(false)
         });

      }, (error) => {
         // setUpdating(false);
         console.log(error.message);
         Modal.error({
            title: 'Student Profile',
            content: error.response.data,
            centered: true,
            onOk: () => setUpdating(false)
         });
      });
   }

   const handleUpdate = () => {
      form.validateFields().then(() => {
         DoUpdateProfile();
      }).catch((error) => {
         console.log('Validation Error:', error);
         Modal.info({
            title: 'Student Profile',
            content: 'Please fill out all required fields.',
            centered: true
         });
      });
   }

   return (
      <>
         <ProcessingModal
            processing={updating}
            title={'Brainee LMS'}
         />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <ul>
                     <li className="active">Student Information</li>
                  </ul>
               </div>

               <div className="dashboard-item-inner3">
                  {
                     (studentInfo !== null && studentOtherInfo !== null) &&
                     <div style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'auto', backgroundColor: '#F2F2F2' }}>
                        <div style={{ marginTop: '35px', marginBottom: '20px', width: '100%', maxWidth: '1000px', height: '100%', border: '1px solid #ccc', borderRadius: '7px', backgroundColor: '#FFF', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                           <div className='block-container'>
                              <div className="login-header align-left" style={{ margin: '35px 35px' }}>
                                 <h3><span>STUDENT INFORMATION</span></h3>
                                 <p className="text-muted" style={{ fontSize: '18px' }}>Learner's Profile</p>
                              </div>

                              <div className='align-right' style={{ width: '165px', height: '165px', paddingTop: '35px', paddingRight: '35px' }}>
                                 <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '7px',
                                    height: '125px',
                                    border: '2px solid rgba(30, 30, 30, 0.1)',
                                 }}>
                                    {/* <Image src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${applicationInfo.picture}`} preview={false} height={'125px'} /> */}
                                    <Image src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${studentInfo.image}`} preview={true} height={'100%'} />
                                 </div>
                              </div>
                           </div>

                           <Form
                              form={form}
                              autoComplete="off"
                              layout='vertical'
                              style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}
                           >
                              {
                                 sectionedPIConfig !== null &&
                                 <>
                                    <SectionedFormRenderer sectionedFormConfig={sectionedPIConfig} form={form} />
                                    {
                                       !updating &&
                                       putFieldValues(studentInfo)
                                    }

                                    <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                                       <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Uploaded Documents</Title>
                                    </Divider>

                                    <Row gutter={[12, 12]}>
                                       <Col xs={24} sm={24} md={24} lg={24}>
                                          <li style={{ marginBottom: '5px' }}>
                                             Birth Certificate (PSA Accredited) <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(bc)}><EyeOutlined /></Button>
                                          </li>
                                          <li>
                                             Report Card or Diploma <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(rc)}><EyeOutlined /></Button>
                                          </li>
                                       </Col>
                                    </Row>

                                    <Row gutter={[12, 12]}>
                                       <Col xs={24} sm={24} md={24} lg={24} style={{ display: "flex", justifyContent: "center" }}>
                                          <Button className='button-shadow' type='primary' onClick={handleUpdate} shape='round' htmlType="submit">Save Profile</Button>
                                       </Col>
                                    </Row>
                                 </>
                              }
                           </Form>
                        </div>
                     </div>
                  }

               </div>
            </div>
         </div>

         <Modal
            key='preview-doc'
            title={"Preview "}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            width='75%'

            onCancel={() => { setModalVisible(false); }}
            onOk={() => { setModalVisible(false); }}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               {renderPreviewer(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${docToView}`)}
            </div>
         </Modal>


      </>
   );
}