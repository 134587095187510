import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Breadcrumb, Empty, message, Modal, Spin } from 'antd';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Split from 'react-split';
import './Assessment.css';
import ProcessingModal from '../../components/processing/ProcessingModal';

import AssessmentAssignTool from '../../components/assessment/AssessmentAssignTool';
import AssessmentTools from '../../components/assessment/AssessmentTools';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import { CardRequestHelper } from '../../helper/AQHelper';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

// Constants
const ASSESSMENT_API = process.env.REACT_APP_API_ASSESSMENT;
const INITIAL_ASS_OPTIONS = {
   id: '',
   assessment_id: '',
   assigned_by: '1',
   duration: '1',
   allowed_attempts: '1',
   passing_percentage: '50',
   allow_result_viewing: '0',
   enable_timer: '0',
   start_date: '',
   end_date: '',
   assigned_students: '',
   term: '',
};

const INITIAL_ASS_INFO = {
   Id: '',
   Title: '',
   Term: '',
   File_url: '',
   Question_type: '1',
   Created_by: '',
   Date_created: '',
   Question_sheet: [],
   Level: '',
   Subject: '',
   Instruction: '',
};

// Utility Functions
const fetchAssessmentInfo = async (id) => {
   const response = await axios.get(`${ASSESSMENT_API}/info?id=${id}&at=summative`);
   const data = response.data;
   return {
      ...INITIAL_ASS_INFO,
      Id: data.id,
      Title: data.title,
      Term: data.term,
      File_url: data.file_url,
      Question_type: data.question_type,
      Created_by: data.created_by,
      Date_created: data.date_created,
      Question_sheet: JSON.parse(data.question_sheet),
      Level: data.level,
      Subject: data.subject,
      Instruction: data.instruction,
   };
};

const hasAssessmentAnswers = async (id) => {
   const response = await axios.get(`${ASSESSMENT_API}/hasanswers?assessment_id=${id}&at=summative`);
   return response.data;
};

// Main Component
export function EditManual(props) {
   const location = useLocation();
   const navigate = useNavigate();
   const userId = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const userRole = Utils.getCurrentUserInfo(Enums.UserInfo.role).toLowerCase();

   const [answerSheetData, setAnswerSheetData] = useState(() => {
      const stored = localStorage.getItem(`${userId}_sa_asd`);
      return stored ? JSON.parse(stored) : [];
   });
   const [assInfo, setAssInfo] = useState(INITIAL_ASS_INFO);
   const [assOptions, setAssOptions] = useState(INITIAL_ASS_OPTIONS);
   const [hasAnswers, setHasAnswers] = useState(false);
   const [showAssessmentTools, setShowAssessmentTools] = useState(true);
   const [showAssignment, setShowAssignment] = useState(false);
   const [loading, setLoading] = useState(true);
   const [updating, setUpdating] = useState(false);

   // Redirect if unauthorized
   useEffect(() => {
      Utils.verifyJwt();

      const allowedRoles = ['admin', 'teacher', 'co-teacher', 'registrar', 'guidance'];
      if (!allowedRoles.includes(userRole.toLowerCase())) {
         navigate('/home');
         return;
      }

      const loadAssessment = async () => {
         try {
            const assessmentId = location.state?.assessmentID;
            if (!assessmentId) throw new Error('No assessment ID provided');

            setLoading(true);
            const hasAnswers = await hasAssessmentAnswers(assessmentId);
            const assessmentData = await fetchAssessmentInfo(assessmentId);

            setHasAnswers(hasAnswers);
            setAssInfo(assessmentData);
            setAnswerSheetData(assessmentData.Question_sheet);
         } catch (error) {
            console.error('Error loading assessment:', error);
            Modal.error({ title: 'Error', content: 'Failed to load assessment data.' });
         } finally {
            setLoading(false);
         }
      };

      loadAssessment();
   }, [location.state?.assessmentID, navigate, userRole]);

   // Handlers
   const handleDragEnd = useCallback((result) => {
      if (!result.destination) return;

      const reordered = [...answerSheetData];
      const [movedItem] = reordered.splice(result.source.index, 1);
      reordered.splice(result.destination.index, 0, movedItem);

      setAnswerSheetData(reordered);
      setAssInfo((prev) => ({ ...prev, Question_sheet: reordered }));
   }, [answerSheetData]);

   const handleCardRequest = useCallback((method, type, data, idx, template) => {
      const updatedSheet = CardRequestHelper(method, type, data, idx, answerSheetData, template);
      setAnswerSheetData(updatedSheet);
      setAssInfo((prev) => ({ ...prev, Question_sheet: updatedSheet }));
      localStorage.setItem(`${userId}_sa_asd`, JSON.stringify(updatedSheet));
      localStorage.removeItem(`${userId}_sa_ass_options`);
   }, [answerSheetData, userId]);

   const handleCardRequest2 = (method, type, data, idx, template) => {
      var asd = CardRequestHelper(method, type, data, idx, answerSheetData, template);

      setAnswerSheetData(asd);

      //--Save to local storage in case something happens to the browser
      localStorage.removeItem(userId + "sa_asd");
      localStorage.removeItem(userId + "sa_ass_options");
   }

   const handleSave = useCallback(
      async (updatedInfo) => {
         const key = 'updatable';
         try {
            setUpdating(true);
            const payload = {
               ...assInfo,
               Title: updatedInfo.title,
               Term: updatedInfo.term,
               Question_sheet: JSON.stringify(answerSheetData),
               Level: updatedInfo.level,
               Subject: updatedInfo.subject,
               Instruction: updatedInfo.instruction,
            };

            const response = await axios.post(`${ASSESSMENT_API}/update?at=summative`, payload);
            if (response.data) {
               setUpdating(false);

               Modal.success({
                  title: 'Summative Assessment',
                  content: 'Assessment updated successfully.',
                  centered: true,
                  onOk: () => navigate(-1),
               });
            }
         } catch (error) {
            console.error('Save failed:', error);
            setUpdating(false);
            Modal.error({ title: 'Summative Assessment', content: 'Failed to update assessment.' });
         }
      },
      [assInfo, answerSheetData, navigate]
   );

   const toggleAssignmentView = () => {
      setShowAssignment((prev) => !prev);
      setShowAssessmentTools((prev) => !prev);
   };

   const handleSetOptions = (options) => setAssOptions({ ...options });

   // Render Helpers
   const renderBreadcrumb = () => (
      <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
         {userRole !== 'registrar' ? (
            <>
               <Breadcrumb.Item><Link to="/home">Home</Link></Breadcrumb.Item>
               <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
               <Breadcrumb.Item><Link to="/assessment">Summative Assessment</Link></Breadcrumb.Item>
               <Breadcrumb.Item>Edit</Breadcrumb.Item>
            </>
         ) : (
            <>
               <Breadcrumb.Item><Link to="/assessment">Examinations For Online Applicants</Link></Breadcrumb.Item>
               <Breadcrumb.Item>Edit</Breadcrumb.Item>
            </>
         )}
      </Breadcrumb>
   );

   const renderQuestionList = () => {
      if (loading) return <div className='temp-answer-sheet-content2'><Spin size="large" tip="Loading, please wait..." /></div>;
      if (!answerSheetData.length) return <Empty description="No items added." />;

      return hasAnswers ? (
         <SimpleBarReact>
            <ul style={{ paddingRight: '15px' }}>
               {answerSheetData.map((item, idx) => (
                  <li key={item.id}>
                     <BraineeQuestionCard
                        {...item}
                        index={idx + 1}
                        id={idx}
                        mode="edit"
                        parentCallback={handleCardRequest}
                        assessment_type="summative"
                        question_type={'2'}
                        templateReplaceCallback={handleCardRequest2}
                        has_answers={hasAnswers}
                     />
                  </li>
               ))}
            </ul>
         </SimpleBarReact>
      ) : (
         <SimpleBarReact>
            <DragDropContext onDragEnd={handleDragEnd}>
               <Droppable droppableId="questions">
                  {(provided) => (
                     <ul {...provided.droppableProps} ref={provided.innerRef} style={{ paddingRight: '15px' }}>
                        {answerSheetData.map((item, idx) => (
                           <Draggable key={item.id} draggableId={item.id} index={idx}>
                              {(provided) => (
                                 <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <BraineeQuestionCard
                                       {...item}
                                       index={idx + 1}
                                       id={idx}
                                       question_type={'2'}
                                       mode="edit"
                                       parentCallback={handleCardRequest}
                                       assessment_type="summative"
                                       templateReplaceCallback={handleCardRequest2}
                                       has_answers={hasAnswers}
                                    />
                                 </li>
                              )}
                           </Draggable>
                        ))}
                        {provided.placeholder}
                     </ul>
                  )}
               </Droppable>
            </DragDropContext>
         </SimpleBarReact>
      );
   };

   return (
      <>
         <ProcessingModal
            processing={updating}
            title={'Brainee LMS'}
         />
         <div className="dashboard-area-all" style={{ paddingRight: 0 }}>
            <Split sizes={[60, 40]} direction="horizontal" className="split-flex">
               <div className="dashboard-item">
                  {renderBreadcrumb()}
                  <div className="answer-sheet-content2">{renderQuestionList()}</div>
               </div>
               <div className="notice-item">
                  <div className="answer-sheet-content">
                     {showAssignment && (
                        <AssessmentAssignTool
                           mode="normal"
                           options={assOptions}
                           parentCallback={handleSetOptions}
                           backCallback={toggleAssignmentView}
                        />
                     )}
                     {showAssessmentTools && assInfo.Id ? (
                        <SimpleBarReact>
                           <AssessmentTools
                              key="editManual"
                              data={assInfo}
                              cardRequestCallback={handleCardRequest}
                              showHideAssCallback={toggleAssignmentView}
                              saveCallback={handleSave}
                              showToggle={false}
                              mode="edit"
                              type="manual"
                              assessment_type="summative"
                           />
                        </SimpleBarReact>
                     ) : (
                        <div className='temp-answer-sheet-content2'><Spin size="large" tip="Loading, please wait..." /></div>
                     )}
                  </div>
               </div>
            </Split>
         </div>
      </>
   );
};

// export default EditManual;