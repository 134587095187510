import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Table, Typography, Space, Tooltip, Button, Badge, Row, Col, Tag, Spin, Popconfirm, Modal, DatePicker, Image, Select, Radio } from 'antd';
import { EyeOutlined, SearchOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import moment from 'moment';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import DocumentViewer from '../../components/document-viewer/DocumentViewer';

const { Text, Link } = Typography;
const { Option } = Select;

export function ApplicationList() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = useState("");
   const [searchedColumn, setSearchedColumn] = useState("");

   const subdomain = localStorage.getItem("lms-subdomain");
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const customFormat = value => `${value.format('MMM. D, YYYY h:mm a')}`;
   // const subdomain = localStorage.getItem("lms-subdomain");

   const [legendCount, setLegendCount] = useState({
      pending: 0,
      accepted: 0,
      not_accepted: 0,
      ready_for_exam: 0,
      passed: 0,
      failed: 0,
      approved: 0,
      not_approved: 0,
      for_interview: 0,
      reconsidered: 0,
      rejected: 0,
      for_assessment: 0,
      interview_passed_with_condition: 0,
      enrolled: 0
   });

   const [applicantList, setApplicantList] = useState([]);
   const [applicantListFiltered, setApplicantListFiltered] = useState([]);
   const [listLoading, setListLoading] = useState(false);
   const [updating, setUpdating] = useState(false);
   const [schedule, setSchedule] = useState(null);
   const [applicantID, setApplicantID] = useState(null);
   const [validPoP, setValidPoP] = useState(false);
   const [docToView, setDocToView] = useState(null);
   const [bankDetails, setBankDetails] = useState(null);
   const [examSchedule, setExamSchedule] = useState(null);
   const [examStatus, setExamStatus] = useState("exam_passed");

   const [modalSchedule, setModalSchedule] = useState(false);
   const [modalExamSchedule, setModalExamSchedule] = useState(false);
   const [modalExamStatus, setModalExamStatus] = useState(false);
   const [modalPoPValidate, setModalPoPValidate] = useState(false);
   const [modalAssessmentPoPValidate, setModalAssessmentPoPValidate] = useState(false);
   const [previewDoc, setPreviewDoc] = useState(false);
   const [modalViewOnsitePayment, setModalViewOnsitePayment] = useState(false);

   const [selectedScheme, setSelectedScheme] = useState(null);
   const [paymentSchemes, setPaymentSchemes] = useState([]);
   const [currentDocument, setCurrentDocument] = useState(null);
   const [selectedSOF, setSelectedSOF] = useState(null);
   const [sof, setSOF] = useState([]);

   useEffect(() => {
      Utils.verifyJwt();

      window.addEventListener('error', e => {
         if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
            const resizeObserverErrDiv = document.getElementById(
               'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
               'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
               resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
               resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
         }
      });

      getApplicants();
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
               </Button>
               <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Last Name',
         key: 'last_name',
         fixed: 'left',
         sorter: (a, b) => { return a.last_name.localeCompare(b.last_name) },
         ...getColumnSearchProps('last_name'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.last_name}>
               <Text><span style={{ textTransform: 'capitalize' }}>{object.last_name}</span></Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'First Name',
         key: 'first_name',
         fixed: 'left',
         sorter: (a, b) => { return a.first_name.localeCompare(b.first_name) },
         ...getColumnSearchProps('first_name'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.first_name}>
               <Text><span style={{ textTransform: 'capitalize' }}>{object.first_name}</span></Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Middle Name',
         key: 'middle_name',
         sorter: (a, b) => { return a.middle_name.localeCompare(b.middle_name) },
         ...getColumnSearchProps('middle_name'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.middle_name}>
               <Text><span style={{ textTransform: 'capitalize' }}>{object.middle_name}</span></Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Gender',
         key: 'gender',
         sorter: (a, b) => { return a.gender.localeCompare(b.gender) },
         ...getColumnSearchProps('gender'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Text>
               {
                  object.gender !== null
                     ?
                     <span style={{ textTransform: 'capitalize' }}>{object.gender}</span>
                     :
                     <></>
               }
            </Text>
         ),
         width: '8%'
      },
      {
         title: 'Applying For',
         key: 'applying_for',
         ...getColumnSearchProps('applying_for'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            object.applying_for !== null && object.applying_for !== '' &&
            <Text>{object.applying_for}</Text>
         ),
         width: '10%'
      },
      {
         title: 'Date Applied',
         key: 'date_applied',
         sorter: (a, b) => { return a.date_applied.localeCompare(b.date_applied) },
         render: object => (
            <Text>
               {moment(object.date_applied).format('MMM. DD, YYYY hh:mm A')}
            </Text>
         ),
         width: '12%'
      },
      {
         title: 'Application Status',
         key: 'application_status',
         ...getColumnSearchProps('application_status'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <div style={{ width: "100%", justifyContent: "left", display: "flex", flexWrap: "wrap" }}>
               {
                  ((object.exam_status === null || object.exam_status === '') &&
                     object.application_status !== 'enrolled' &&
                     object.application_status !== 'approved' &&
                     object.application_status !== 'for_interview' &&
                     object.application_status !== 'ready_for_exam' &&
                     object.application_status !== 'for_assesment' &&
                     object.is_pop_valid === '1') &&
                  RenderTag('paid')
               }
               {
                  (object.tuition_assessment_pop_valid !== null && object.application_status !== "enrolled") &&
                  RenderTag(object.tuition_assessment_pop_valid === '1' || object.tuition_assessment_pop_valid === 1 ? 'paid' : 'not_paid')
               }
               {
                  (object.exam_status !== null && object.exam_status !== '' && object.application_status === 'for_interview') &&
                  RenderTag(object.exam_status)
               }
               {
                  (object.interview_status === "interview_passed_with_condition" && object.application_status === "for_assessment") &&
                  RenderTag(object.interview_status)
               }

               {
                  object.application_status === 'ready_for_exam'
                     ?
                     RenderTag(object.application_status, object.exam_mode)
                     :
                     RenderTag(object.application_status)
               }
            </div>

         ),
         width: '22%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return (
               <>
                  {
                     RenderActionButtons(payload)
                  }
               </>
            );
         },
         width: '19%'
      },
   ].filter(item => !item.hidden);

   const getApplicants = () => {
      setListLoading(true);
      axios.get(`${process.env.REACT_APP_API_CPH}/getonlineapplicants`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            //--Update legend counts
            var loclegend = {
               pending: 0,
               accepted: 0,
               rejected: 0,
               ready_for_exam: 0,
               passed: 0,
               failed: 0,
               approved: 0,
               not_approved: 0,
               for_interview: 0,
               reconsidered: 0,
               for_assessment: 0,
               interview_passed_with_condition: 0,
               enrolled: 0
            }

            for (var i = 0; i < data.length; i++) {
               switch (data[i].application_status) {
                  case 'pending':
                     loclegend.pending++;
                     break;
                  case 'accepted':
                     loclegend.accepted++;
                     break;
                  case 'rejected':
                     loclegend.rejected++;
                     break;
                  case 'ready_for_exam':
                     loclegend.ready_for_exam++;
                     break;
                  case 'exam_passed':
                     loclegend.passed++;
                     break;
                  case 'exam_failed':
                     loclegend.failed++;
                     break;
                  case 'approved':
                     loclegend.approved++;
                     break;
                  case 'not_approved':
                     loclegend.not_approved++;
                     break;
                  case 'on_hold':
                  case 'for_interview':
                     loclegend.for_interview++;
                     break;
                  case 'for_assessment':
                     loclegend.for_assessment++;
                     break;
                  case 'reconsidered':
                     loclegend.reconsidered++;
                     break;
                  case 'interview_passed_with_condition':
                     loclegend.interview_passed_with_condition++;
                     break;
                  case 'enrolled':
                     loclegend.enrolled++;
                     break;
                  default:
               }
            }

            setLegendCount({ ...loclegend });
            setApplicantList(data);
            setApplicantListFiltered(data);
            setListLoading(false);
         }
      }, (error) => {
         setListLoading(false);
         console.log(error.message);
      });
   }

   const RenderActionButtons = (payload) => {
      return (
         <div style={{ width: "100%", justifyContent: "left", display: "flex", flexWrap: "wrap", gap: "5px" }}>
            {
               payload.application_status !== "for_assessment"
                  ?
                  <div>
                     <Button type='primary' size='small' shape='round' onClick={() => handleReviewApplication(payload.id, payload.application_status)}>{payload.application_status === 'pending' && user_role === "Registrar" ? 'Review' : 'View'}</Button>
                  </div>
                  :
                  user_role !== "Registrar" &&
                  <div>
                     <Button type='primary' size='small' shape='round' onClick={() => handleReviewApplication(payload.id, payload.application_status)}>{payload.application_status === 'pending' && user_role === "Registrar" ? 'Review' : 'View'}</Button>
                  </div>
            }
            {
               (user_role === 'Cashier' && payload.application_status === "accepted") &&
               <div>
                  <Button type='primary' size='small' shape='round' onClick={() => handleViewDoc(payload.id, payload.bank_details)}><EyeOutlined /> Proof of Payment</Button>
               </div>
            }
            {
               ((user_role === 'Guidance' || user_role === 'Registrar') && payload.application_status === 'approved') &&
               <div>
                  <Button type='primary' size='small' shape='round' onClick={() => handleInterview(payload.id, 'view', payload.exam_mode)}>Interview Result</Button>
               </div>
            }
            {
               (payload.application_status === 'rejected' && user_role === 'Registrar') &&
               <div>
                  <Popconfirm
                     title="Do you want to Unreject this applicant?"
                     onConfirm={() => handleUnreject(payload.id)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button type='primary' size='small' shape='round'>Unreject</Button>
                  </Popconfirm>
               </div>
            }
            {
               (user_role === 'Guidance' && payload.application_status === 'accepted') &&
               <Button type='primary' size='small' shape='round' onClick={() => showModalExamSchedule(payload.id, true)}>Set Exam</Button>
            }
            {
               (payload.application_status === 'ready_for_exam' && user_role === 'Guidance') &&
               <>
                  <div>
                     <Button type='primary' size='small' shape='round'>Schedule: {payload.exam_schedule}</Button>
                  </div>
                  {
                     (payload.exam_ready === true && payload.exam_mode === 'onsite') &&
                     <>
                        <div>
                           <Button type='primary' size='small' shape='round' onClick={() => showModalExamSchedule(payload.id, true)}>Re-schedule Exam</Button>
                        </div>
                        <div>
                           <Button type='primary' size='small' shape='round' onClick={() => showModalExamStatus(payload.id, true)}>Set Exam Status</Button>
                        </div>
                     </>
                  }
               </>
            }
            {
               (payload.application_status === 'exam_failed' && user_role === 'Guidance') &&
               <div>
                  <Popconfirm
                     title="Do you want to reconsider this applicant?"
                     onConfirm={() => handleReconsider(payload.id)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button type='primary' size='small' shape='round'>Reconsider</Button>
                  </Popconfirm>
               </div>
            }
            {
               ((payload.application_status === 'exam_passed' || payload.application_status === 'reconsidered') && user_role === 'Guidance') &&
               <div>
                  <Button type='primary' size='small' shape='round' onClick={() => showModalSchedule(payload.id, true)}>Set Interview</Button>
               </div>
            }
            {
               (payload.application_status === 'on_hold' || payload.application_status === 'for_interview' || payload.application_status === "interview_passed_with_condition") && user_role === 'Guidance' &&
               <>
                  {
                     payload.exam_mode === "online"
                        ?
                        payload.interview_ready === true
                           ?
                           <>
                              <div>
                                 <Button type='primary' size='small' shape='round' onClick={() => handleInterview(payload.id, "edit", payload.exam_mode)}>Interview</Button>
                              </div>
                              <div>
                                 <Button type='primary' size='small' shape='round' onClick={() => showModalSchedule(payload.id, true)}>Re-schedule Interview</Button>
                              </div>
                           </>
                           :
                           <div>
                              <Button type='primary' size='small' shape='round'>Schedule: {payload.interview_schedule}</Button>
                           </div>
                        :
                        <div>
                           <Button type='primary' size='small' shape='round' onClick={() => handleInterview(payload.id, "edit", payload.exam_mode)}>Interview</Button>
                        </div>
                  }
               </>
            }
            {
               (payload.application_status === "for_assessment" && (user_role === "Registrar" || user_role === "Guidance")) &&
               <>
                  <div>
                     <Button type='primary' size='small' shape='round' onClick={() => handleInterview(payload.id, 'view', payload.exam_mode)}>Interview Result</Button>
                  </div>
               </>
            }
            {
               (payload.application_status === "for_assessment" && payload.tuition_assessment_pop_valid === "1" && user_role === "Registrar") &&
               <>
                  <div>
                     <Popconfirm
                        title="Are you sure you want to mark this applicant as enrolled?"
                        onConfirm={() => handleMarkAsEnrolled(payload.id)}
                        okText="Yes"
                        cancelText="No"
                        placement='left'
                     >
                        <Button type='primary' size='small' shape='round'>Mark as Enrolled</Button>
                     </Popconfirm>

                  </div>
               </>
            }
            {
               (user_role === 'Cashier' && payload.application_status === "for_assessment") &&
               <>
                  {
                     (payload.tuition_assessment_scheme !== null && payload.tuition_assessment_scheme !== "") &&
                     <div>
                        {/* <Button type='primary' size='small' shape='round' disabled={(payload.tuition_assessment_scheme === null || payload.tuition_assessment_scheme === "")} onClick={() => handleViewAssessment(payload.tuition_assessment_scheme, payload.tuition_assessment_terms)}><EyeOutlined /> Selected Payment Scheme</Button> */}
                        <Button type='primary' size='small' shape='round' onClick={() => handleViewAssessment(payload.tuition_assessment_scheme, payload.tuition_assessment_terms)}><EyeOutlined /> Selected Payment Scheme</Button>
                     </div>

                  }
                  {
                     (payload.tuition_assessment_pop === null || payload.tuition_assessment_pop === "") &&
                     <div>
                        <Button type='primary' size='small' shape='round' onClick={() => handleViewOnsitePayment(payload.id)}><SolutionOutlined /> On-Site Payment</Button>
                     </div>
                  }
                  {
                     (payload.tuition_assessment_pop !== null && payload.tuition_assessment_pop !== "") &&
                     <div>
                        <Button type='primary' size='small' shape='round' onClick={() => handleViewAssessmentPoP(payload.id, payload.tuition_assessment_pop)}><EyeOutlined /> Proof of Payment</Button>
                     </div>
                  }

               </>

            }
         </div>
      );
   }

   const RenderTag = (status, mode = "") => {
      var color = '';

      switch (status) {
         case 'pending':
            color = 'gold';
            break;
         case 'accepted':
         case 'exam_passed':
         case 'interview_passed':
         case 'interview_passed_with_condition':
            color = 'green'
            break;
         case 'rejected':
         case 'not_approved':
         case 'not_paid':
         case 'interview_failed':
         case 'exam_failed':
            color = 'red';
            break;
         case 'ready_for_exam':
            color = 'pink';
            break;
         case 'approved':
         case 'paid':
         case 'enrolled':
            color = 'cyan';
            break;
         case 'on_hold':
         case 'for_interview':
         case 'for_assessment':
            color = 'blue';
            break;
         case 'reconsidered':
            color = 'purple';
            break;
         default:
      }

      return <>
         <div>
            <Tag color={color}>
               {
                  mode !== ''
                     ?
                     '(' + mode + ') - ' + status.replace(/_/g, ' ').toUpperCase()
                     :
                     status.replace(/_/g, ' ').toUpperCase()
               }
            </Tag>
         </div>
      </>
   }

   const RenderStatusBadge = (status_count, label, color) => {
      return <>
         <div>
            <Badge count={status_count} style={{ backgroundColor: color }} overflowCount={999}>
               <Tag
                  color={color}
                  style={{ cursor: status_count > 0 ? 'pointer' : 'default', padding: '7px' }}
                  onClick={() => handleFilterListByStatus(label, status_count)}
               >
                  {/* {label.toUpperCase()} */}
                  {label.replace(/_/g, ' ').toUpperCase()}
               </Tag>
            </Badge>
         </div>
      </>
   }

   const RenderStatusCounts = () => {
      return <>
         <div style={{ width: "100%", justifyContent: "center", display: "flex", flexWrap: "wrap", gap: "10px", paddingBottom: "10px" }}>
            {RenderStatusBadge(legendCount.pending, "pending", "gold")}
            {RenderStatusBadge(legendCount.accepted, "accepted", "green")}
            {RenderStatusBadge(legendCount.rejected, "rejected", "red")}
            {RenderStatusBadge(legendCount.ready_for_exam, "ready_for_exam", "pink")}
            {RenderStatusBadge(legendCount.passed, "passed", "green")}
            {RenderStatusBadge(legendCount.exam_failed, "exam_failed", "red")}
            {RenderStatusBadge(legendCount.reconsidered, "reconsidered", "purple")}
            {RenderStatusBadge(legendCount.for_interview, "for_interview", "blue")}
            {RenderStatusBadge(legendCount.interview_passed_with_condition, "interview_passed_with_condition", "orange")}
            {RenderStatusBadge(legendCount.for_assessment, "for_assessment", "blue")}
            {RenderStatusBadge(legendCount.enrolled, "enrolled", "cyan")}
            <Button className='button-shadow' type='primary' shape='round' onClick={() => getApplicants()}>Refresh</Button>
         </div>
      </>
   }

   const handleFilterListByStatus = (status, count) => {
      if (count > 0) {
         var filteredList = applicantList.filter(student => student.application_status === status);

         setApplicantListFiltered(filteredList);
      }
   }

   const handleReviewApplication = (id, status) => {
      navigate(`/reviewapplication?id=${id}`, { state: { status } });
   }

   const handleReconsider = (id) => {
      setUpdating(true);
      var url = `${process.env.REACT_APP_API_CPH}/reconsiderapplicant?id=${id}`;

      axios.post(url).then((response) => {
         if (response.data) {
            setUpdating(false);

            Modal.success({
               title: 'Online Application',
               content: 'Reconsider successful.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setUpdating(false);

         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
         console.log(error.message);
      });
   }

   const showModalSchedule = (applicant_id, show) => {
      setApplicantID(applicant_id);
      setModalSchedule(show);
   }

   const handleSetInterviewSchedule = () => {
      setUpdating(true);
      var url = `${process.env.REACT_APP_API_CPH}/setinterviewschedule?id=${applicantID}&sched=${moment(schedule).format("YYYY-MM-DD HH:mm:ss")}`;

      axios.post(url).then((response) => {
         if (response.data) {
            setModalSchedule(false);
            setUpdating(false);
            Modal.success({
               title: 'Online Application',
               content: 'Setting interview schedule successful.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setModalSchedule(false);
         setUpdating(false);
         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleInterview = (id, mode = 'edit', exam_mode = "online") => {
      navigate(`/interview?id=${id}`, { state: { mode, exam_mode } });
   }

   const handleViewDoc = (id, bank_details) => {
      //-- Get proof of payment url from documents
      var items = applicantListFiltered.filter(item => item.id === id);
      let docs = JSON.parse(items[0].documents);

      setBankDetails(bank_details);
      setDocToView(docs.proof_of_payment);
      setApplicantID(id);
      setModalPoPValidate(true);
   }

   const handleMarkAsPaid = (val, t) => {
      setUpdating(true);

      var url = `${process.env.REACT_APP_API_CPH}/markaspaid?id=${applicantID}&t=${t}&v=${val}`;

      axios.post(url).then((response) => {
         if (response.data) {
            setModalPoPValidate(false);
            setUpdating(false);
            Modal.success({
               title: 'Online Application',
               content: 'Setting mark as paid successful.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setModalPoPValidate(false);
         setUpdating(false);
         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
      });
   }

   const handleUnreject = (id) => {
      setUpdating(true);
      var url = `${process.env.REACT_APP_API_CPH}/changeapplicationstatus?id=${id}&ns=pending`;

      axios.post(url).then((response) => {
         if (response.data) {
            setUpdating(false);
            Modal.success({
               title: 'Online Application',
               content: 'Unreject successful.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setUpdating(false);
         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
      });
   }

   const showModalExamSchedule = (applicant_id, show) => {
      setApplicantID(applicant_id);
      setModalExamSchedule(show);
   }

   const showModalExamStatus = (applicant_id, show) => {
      setApplicantID(applicant_id);
      setModalExamStatus(show);
   }

   const handleSetOnsiteExamSchedule = () => {
      setModalExamSchedule(false);
      setUpdating(true);
      var url = `${process.env.REACT_APP_API_CPH}/setexamschedule?id=${applicantID}&sched=${moment(examSchedule).format("YYYY-MM-DD HH:mm:ss")}`;

      axios.post(url).then((response) => {
         if (response.data) {
            setUpdating(false);
            Modal.success({
               title: 'Online Application',
               content: 'Setting on-site exam schedule successful.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setUpdating(false);
         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleChangeExamStatus = () => {
      setModalExamStatus(false);
      setUpdating(true);
      var url = `${process.env.REACT_APP_API_CPH}/changeapplicationstatus?id=${applicantID}&ns=${examStatus}`;

      axios.post(url).then((response) => {
         if (response.data) {
            setUpdating(false);
            Modal.success({
               title: 'Online Application',
               content: 'Unreject successful.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setUpdating(false);
         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
      });
   }

   const handleViewAssessment = (selected_scheme, schemes) => {
      setSelectedScheme(selected_scheme);
      setDocToView(schemes);
      setPreviewDoc(true);
   };

   const handleViewAssessmentPoP = (id, pop) => {
      setApplicantID(id);
      setDocToView(pop);
      setModalAssessmentPoPValidate(true);
   }

   const handleMarkAsEnrolled = (id) => {
      setUpdating(true);
      var url = `${process.env.REACT_APP_API_CPH}/tagasenrolled?id=${id}`;

      axios.post(url).then((response) => {
         if (response.data) {
            setUpdating(false);
            Modal.success({
               title: 'Online Application',
               content: 'Applicant is now enrolled.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setUpdating(false);
         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
      });
   }

   // const GetPaymentSchemes = (id) => {
   //    axios.get(`${process.env.REACT_APP_API_CPH}/paymentschemes?id=${id}`).then((response) => {
   //       if (response.data) {
   //          const data = response.data;

   //          if (data !== null && data !== "") {
   //             setApplicantID(data[0].id);
   //             var ps = JSON.parse(data[0].tuition_assessment_terms);

   //             if (data[0].tuition_assessment_scheme !== null && data[0].tuition_assessment_scheme !== "") {
   //                // var result = ps.find(item => item.value = data[0].tuition_assessment_scheme); //-- Buggy
   //                let result;
   //                ps.forEach(item => {
   //                   if (item.value === data[0].tuition_assessment_scheme) {
   //                      result = item;
   //                   }
   //                });
   //             }

   //             setPaymentSchemes(ps);
   //             setModalViewOnsitePayment(true);
   //          }
   //       }
   //    }, (error) => {
   //       console.log(error.message);
   //    });
   // }

   const GetSOF = (id) => {
      axios.get(`${process.env.REACT_APP_API_CPH}/getsof?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            if (data !== null && data !== "") {
               if (data.length > 1) {
                  setSOF(data);
                  setSelectedSOF(null);
                  setCurrentDocument(null);
               }
               else {
                  setSOF([])
                  setSelectedSOF(data[0].description);
                  setCurrentDocument(data[0].document);
               }
            }

            GetPaymentSchemes();
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const GetPaymentSchemes = () => {
      axios.get(`${process.env.REACT_APP_API_CPH}/v2/paymentschemes`).then((response) => {
         if (response.data) {
            const data = response.data;

            if (data !== null && data !== "") {
               var ps = data;

               setPaymentSchemes(ps);
               setModalViewOnsitePayment(true);
            }
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleViewOnsitePayment = (applicant_id) => {
      setApplicantID(applicant_id);
      // GetPaymentSchemes(applicant_id);
      GetSOF(applicant_id);
   }

   const handleSetPaymentScheme = () => {
      setUpdating(true);
      // var url = `${process.env.REACT_APP_API_CPH}/updatesafield?id=${applicantID}&fn=tuition_assessment_scheme&fv=${selectedScheme}`;
      var url = `${process.env.REACT_APP_API_CPH}/updateselectedfee?id=${applicantID}&fv1=${selectedScheme}&fv2=${currentDocument}&fv3=${selectedSOF}`;

      axios.post(url).then((response) => {
         if (response.data) {
            setModalViewOnsitePayment(false);
            handleMarkAsPaid(true, "assessment_pop");

            setSOF([])
            setSelectedSOF(null);
            setCurrentDocument(null);
            setSelectedScheme(null);
         }
      }, (error) => {
         setUpdating(false);

         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
      });
   }

   const handleSOFChange = (val) => {
      let _sof = [...sof];
      let result;
      _sof.forEach(item => {
         if (item.description === val) {
            result = item;
         }
      });

      setCurrentDocument(result.document);
      setSelectedSOF(result.description);
   }

   return (
      <>
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <Spin size='large' spinning={updating} tip="Processing request...Please wait.">
                  <div className="dashboard-item-inner">
                     <ul>
                        <li className="active">Student Applicant Tracker</li>
                     </ul>
                  </div>

                  <div className="dashboard-item-inner3">
                     {
                        RenderStatusCounts()
                     }
                     <Table
                        loading={listLoading}
                        size='small'
                        rowKey={'uid'}
                        columns={columns}
                        dataSource={applicantListFiltered}
                        pagination={false}
                        scroll={{
                           x: '1400px',
                           // y: 'calc(100vh - 17.5rem)',
                        }}
                        // style={{ minWidth: "1000px" }}
                        footer={() => 'Total Records: ' + applicantListFiltered.length}
                     />
                  </div>
               </Spin>
            </div>
         </div>

         <Modal
            key="modal-schedule"
            title='Online Application'
            destroyOnClose={true}
            centered
            okText={"Set"}
            cancelText={"Close"}
            open={modalSchedule}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
            onOk={() => handleSetInterviewSchedule()}
            onCancel={() => setModalSchedule(false)}
         >
            <Text style={{ verticalAlign: "middle" }}>Set Interview Schedule</Text>
            <DatePicker
               placeholder="Select Date"
               showTime={{ format: 'hh:mm A', use12Hours: true }}
               format={customFormat}
               style={{ width: "100%" }}
               disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
               onBlur={(event) => setSchedule(event.target.value)}
            />
         </Modal>

         <Modal
            key='pop-validate'
            title={"Validate Proof of Payment"}
            okText={"Paid"}
            cancelText={'Not Paid'}
            okButtonProps={{ type: 'primary', shape: "round" }}
            cancelButtonProps={{ type: 'primary', shape: 'round' }}
            centered
            destroyOnClose={true}
            open={modalPoPValidate}
            width='75%'
            footer={[
               <Button key="paid" type='primary' shape='round' onClick={() => handleMarkAsPaid(true, "exam_pop")}>Paid</Button>,
               <Button key="notpaid" type='primary' shape='round' onClick={() => handleMarkAsPaid(false, "exam_pop")}>Not Paid</Button>,
               <Button key="close" type="primary" shape='round' onClick={() => setModalPoPValidate(false)}>Close</Button>
            ]}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               <div
                  className={'modal-document-viewer'}
                  style={{ height: "calc(100vh - 220px)" }}
               >
                  <Image
                     // src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${docToView}`}
                     src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${docToView}`}
                     style={{ minHeight: "calc(100vh - 220px)", maxHeight: "calc(100vh - 220px)" }}
                  />

               </div>
               <div><Text>Bank Details: <b>{bankDetails}</b></Text></div>
            </div>
         </Modal>

         <Modal
            key="modal-exam-schedule"
            title='Online Application'
            destroyOnClose={true}
            centered
            okText={"Set"}
            cancelText={"Close"}
            open={modalExamSchedule}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
            onOk={() => handleSetOnsiteExamSchedule()}
            onCancel={() => setModalExamSchedule(false)}
         >
            <Text style={{ verticalAlign: "middle" }}>Set Exam Schedule</Text>
            <DatePicker
               placeholder="Select Date"
               showTime={{ format: 'hh:mm A', use12Hours: true }}
               format={customFormat}
               style={{ width: "100%" }}
               disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
               onBlur={(event) => setExamSchedule(event.target.value)}
            />
         </Modal>

         <Modal
            key="modal-exam-status"
            title='Exam Result'
            destroyOnClose={true}
            centered
            okText={"Set"}
            cancelText={"Close"}
            open={modalExamStatus}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
            onOk={() => handleChangeExamStatus()}
            onCancel={() => setModalExamStatus(false)}
         >
            <Space>
               <Text style={{ verticalAlign: "middle" }}>Set Exam Status</Text>
               <Radio.Group
                  name='exam-status'
                  defaultValue={'exam_passed'}
                  buttonStyle='solid'
                  onChange={(e) => setExamStatus(e.target.value)}
               >
                  <Radio.Button value="exam_passed">Passed</Radio.Button>
                  <Radio.Button value="exam_failed">Failed</Radio.Button>
               </Radio.Group>
            </Space>
         </Modal>

         <Modal
            key='pop-validate'
            title={"Validate Proof of Payment"}
            okText={"Paid"}
            cancelText={'Not Paid'}
            okButtonProps={{ type: 'primary', shape: "round" }}
            cancelButtonProps={{ type: 'primary', shape: 'round' }}
            centered
            destroyOnClose={true}
            open={modalAssessmentPoPValidate}
            width='75%'
            footer={[
               <Button key="paid" type='primary' shape='round' onClick={() => handleMarkAsPaid(true, "assessment_pop")}>Paid</Button>,
               <Button key="notpaid" type='primary' shape='round' onClick={() => handleMarkAsPaid(false, "assessment_pop")}>Not Paid</Button>,
               <Button key="close" type="primary" shape='round' onClick={() => setModalAssessmentPoPValidate(false)}>Close</Button>
            ]}
         >
            {/* <div className='essay_list_container' style={{ background: "#fff" }}>
               <div style={{ width: "100%", height: "calc(100vh - 195px)" }}>
                  <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                     <Viewer
                        pageLayout={{
                           transformSize: ({ size }) => ({
                              height: size.height + 30,
                              width: size.width + 30,
                           }),
                        }}
                        fileUrl={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${docToView}`}
                        defaultScale={SpecialZoomLevel.PageWidth}
                        initialPage={0}
                     />
                  </Worker>
               </div>
            </div> */}
            <div className='student_list_container'>
               <DocumentViewer
                  document={docToView}
                  pdfshowdownload={true}
                  pdfshowprint={true}
                  ismodal={true}
                  dvheight={"calc(100vh - 220px)"}
               />
            </div>
         </Modal>

         <Modal
            key='preview-assessment-scheme'
            title={"Preview Assessment"}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={previewDoc}
            width='75%'
            onCancel={() => { setPreviewDoc(false); }}
            onOk={() => { setPreviewDoc(false); }}
            footer={[
               <Row style={{ width: "100%" }}>
                  <Col style={{ width: "70%", textAlign: "left" }}>{'Selected Payment Scheme: '}<b>{selectedScheme}</b></Col>
                  <Col style={{ width: "30%", textAlign: "right" }}>
                     <Button className='button-shadow' size='middle' type='primary' shape='round' onClick={() => setPreviewDoc(false)}>
                        Close
                     </Button>
                  </Col>
               </Row>
            ]}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               <div style={{ width: "100%", height: "calc(100vh - 195px)" }}>
                  <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                     <Viewer
                        pageLayout={{
                           transformSize: ({ size }) => ({
                              height: size.height + 30,
                              width: size.width + 30,
                           }),
                        }}
                        fileUrl={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${docToView}`}
                        defaultScale={SpecialZoomLevel.PageWidth}
                        initialPage={0}
                     />
                  </Worker>
               </div>
            </div>
         </Modal>

         <Modal
            key="modal-onsite-payment"
            title='On-Site Payment'
            destroyOnClose={true}
            centered
            okText={"Paid"}
            cancelText={"Close"}
            open={modalViewOnsitePayment}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
            onOk={() => handleSetPaymentScheme()}
            onCancel={() => setModalViewOnsitePayment(false)}
         >
            <Space direction="vertical">
               {
                  sof.length > 1 &&
                  <div>
                     <Text>Schedule of Fees</Text>&nbsp;&nbsp;
                     <Select
                        key={'uid'}
                        showArrow
                        // disabled={processed}
                        size='middle'
                        style={{ minWidth: "300px" }}
                        allowClear
                        onChange={(val) => handleSOFChange(val)}
                        onClear={() => setSelectedSOF(null)}
                     // defaultValue={selectedScheme}
                     >
                        {sof.map(({ id, document, description }) => {
                           return <Option value={description}>{description}</Option>
                        })}
                     </Select>&nbsp;&nbsp;
                  </div>
               }
               {
                  paymentSchemes.length > 0 &&
                  <div>
                     <Text>Select Payment Terms</Text>&nbsp;&nbsp;
                     <Select
                        key={'uid'}
                        showArrow
                        // disabled={processed}
                        size='middle'
                        style={{ minWidth: "200px" }}
                        allowClear
                        onChange={(val) => setSelectedScheme(val)}
                        onClear={() => setSelectedScheme(null)}
                     // defaultValue={selectedScheme}
                     >
                        {paymentSchemes.map(({ scheme }) => {
                           return <Option value={scheme}>{scheme}</Option>
                        })}
                     </Select>&nbsp;&nbsp;
                  </div>
               }
            </Space>
         </Modal>
      </>
   );
}
