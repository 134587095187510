import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Divider, Typography, Input, Space, Image, Modal, Row, Col, Form, Radio, DatePicker, Spin, Popconfirm, Tag, Select, Checkbox } from 'antd';
import axios from 'axios';
import Split from 'react-split';
import { EyeOutlined } from '@ant-design/icons';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
// import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import moment from 'moment';
import SimpleBarReact from "simplebar-react";
import SectionedFormRenderer from '../../components/utility/SectionedFormRenderer';

export function Interview() {
   const [form] = Form.useForm();
   const navigate = useNavigate();
   const location = useLocation();

   const { Text } = Typography;

   const { Title } = Typography;
   const { TextArea } = Input;
   const { Option } = Select;

   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   const subdomain = localStorage.getItem("lms-subdomain");

   const search = window.location.search;
   const query = new URLSearchParams(search);
   const application_id = query.get('id');

   const [notes, setNotes] = useState(null);
   const [recommendation, setRecommendation] = useState(null);
   const [applicationInfo, setApplicationInfo] = useState(null);
   const [applicationOtherInfo, setApplicationOtherInfo] = useState(null);
   const [rc, setRC] = useState(null);
   const [bc, setBC] = useState(null);
   const [modalVisible, setModalVisible] = useState(false);
   const [docToView, setDocToView] = useState(null);
   const [updating, setUpdating] = useState(false);
   // const [courseOffering, setCourseOffering] = useState([]);
   // const [approvedCourse, setApprovedCourse] = useState(null);
   const [sectionedPIConfig, setSectionedPIConfig] = useState(null);

   const mode = location.state.mode;
   const exam_mode = location.state.exam_mode;

   useEffect(() => {
      Utils.verifyJwt();

      getApplicationInfo(application_id);
   }, []);

   const getFormSectionedFields = (fn) => {
      var url = `${process.env.REACT_APP_API_CPH}/getsectionedfields?fn=${fn}&ft=basic&m=view`;
      /*console.log(url);*/
      axios.get(url)
         .then((response) => {
            if (response.data) {
               var data = response.data;
               setSectionedPIConfig(data);
               //console.log(data);
            }
         })
         .catch((error) => {
            // setLoading(false);
            console.log(error.message);
         });
   };

   const getApplicationInfo = (id) => {
      var url = `${process.env.REACT_APP_API_CPH}/getapplicationinfo?id=${id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            let data = response.data;
            let docs = JSON.parse(data.documents);

            setBC(docs.birth_certificate);
            setRC(docs.report_card);
            setRecommendation(data.interview_recommendation);
            setNotes(data.interview_notes);
            // setApprovedCourse(data.approved_course);
            setApplicationOtherInfo(JSON.parse(data.other_info));
            setApplicationInfo(data);

            getFormSectionedFields(data.form_number);
         }
      }, (error) => {
         // setLoading(false);
         console.log(error.message);
      });
   }

   const putFieldValues = (data) => {
      var fieldValues = JSON.parse(data.other_info);

      for (const sectionKey in sectionedPIConfig) {
         const section = sectionedPIConfig[sectionKey];
         for (const fieldKey in section.fields) {
            if (fieldKey.toLocaleLowerCase().includes("date")) {
               form.setFieldValue(fieldKey, moment(fieldValues[fieldKey], 'YYYY-MM-DD'));
            }
            else {
               form.setFieldValue(fieldKey, fieldValues[fieldKey]);
            }
         }
      }
   }

   const renderPreviewer = (document) => {
      return <>
         <div
            className={'modal-document-viewer'}
            style={{ height: "calc(100vh - 195px)" }}
         >
            <Image
               // src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${document}`}
               src={document}
               style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
            />
         </div>
      </>
   }

   const handleViewDoc = (doctoview) => {
      setDocToView(doctoview);
      setModalVisible(true);
   }

   // const handleUpdateStatus = (newstatus) => {
   //    var url = `${process.env.REACT_APP_API_CPH}/updateapplicationstatus?id=${application_id}&status=${newstatus}`;
   //    axios.post(url).then((response) => {
   //       if (response.data) {

   //          Modal.success({
   //             title: 'Online Application',
   //             content: 'Application was successfuly disapproved.',
   //             centered: true,
   //             onOk: navigate('/applicantlist')
   //          });
   //       }
   //    }, (error) => {
   //       Modal.error({
   //          title: 'Online Application',
   //          content: error.response.data,
   //          centered: true
   //       });
   //       console.log(error.message);
   //    });
   // }

   // const handleSaveInterview = (status) => {
   //    var can_proceed = recommendation !== null && recommendation !== '' && recommendation !== undefined;
   //    if (can_proceed) {
   //       setUpdating(true);

   //       var url = `${process.env.REACT_APP_API_CPH}/approveapplicant?id=${application_id}`;

   //       var data = { notes, recommendation, status }

   //       axios.post(url, data).then((response) => {
   //          if (response.data !== -1) {
   //             setUpdating(false);
   //             Modal.success({
   //                title: 'Online Application',
   //                content: 'Application was successfuly approved.',
   //                centered: true,
   //                onOk: navigate('/applicantlist')
   //             });
   //          } else {
   //             setUpdating(false);

   //             Modal.error({
   //                title: 'Online Application',
   //                content: 'Operation failed.',
   //                centered: true
   //             });
   //          }
   //       }, (error) => {
   //          setUpdating(false);
   //          Modal.error({
   //             title: 'Online Application',
   //             content: 'Operation failed.',
   //             centered: true
   //          });
   //          console.log(error.message);
   //       });
   //    } else {
   //       Modal.info({
   //          title: 'Online Application',
   //          content: (<>
   //             The following fields are required.
   //             <ul>
   //                {
   //                   (recommendation === null || recommendation === '' || recommendation === undefined) &&
   //                   <li>
   //                      Recommendations
   //                   </li>
   //                }
   //             </ul>
   //          </>),
   //          centered: true
   //       });
   //    }
   // }

   const handleSaveInterview = (status) => {
      setUpdating(true);

      var url = `${process.env.REACT_APP_API_CPH}/saveinterview?id=${application_id}`;

      var data = { notes, recommendation, status }

      axios.post(url, data).then((response) => {
         if (response.data) {
            setUpdating(false);
            Modal.success({
               title: 'Online Application',
               content: 'Interview saved.',
               centered: true,
               onOk: navigate('/applicantlist')
            });
         }
      }, (error) => {
         setUpdating(false);
         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
         console.log(error.message);
      });
   }

   // const handleDisapprove = () => {
   //    var can_proceed = notes !== null && notes !== '' && notes !== undefined;
   //    if (!can_proceed) {
   //       Modal.info({
   //          title: 'Online Application',
   //          content: (<>
   //             The following fields are required.
   //             <ul>
   //                {
   //                   notes === null &&
   //                   <li>
   //                      Notes
   //                   </li>
   //                }
   //             </ul>
   //          </>),
   //          centered: true
   //       });
   //       return;
   //    }

   //    setUpdating(true);
   //    var url = `${process.env.REACT_APP_API_CPH}/disapproveapplicant?id=${application_id}`;
   //    var data = { notes, recommendation, 'status': 'not_approved' }
   //    axios.post(url, data).then((response) => {
   //       if (response.data) {
   //          setUpdating(false);
   //          Modal.success({
   //             title: 'Online Application',
   //             content: 'Application was successfuly disapproved.',
   //             centered: true,
   //             onOk: navigate('/applicantlist')
   //          });
   //       }
   //    }, (error) => {
   //       setUpdating(false);
   //       Modal.error({
   //          title: 'Online Application',
   //          content: error.response.data,
   //          centered: true
   //       });
   //       console.log(error.message);
   //    });
   // }

   return (
      <>
         {
            (applicationInfo !== null && applicationOtherInfo !== null) &&
            <div className="dashboard-area-all">
               <Spin size='large' spinning={updating} tip="Processing request...Please wait.">
                  <Split
                     sizes={[65, 35]}
                     direction="horizontal"
                     cursor="col-resize"
                     className="split-flex"
                  >
                     <div className="dashboard-item">

                        <div className="dashboard-item-inner">
                           <ul>
                              <li className="active"><a href="#" onClick={() => navigate('/applicantlist')}>Student Applicants</a></li>
                              <li><span>/</span></li>
                              <li className="active">Interview Applicant</li>
                           </ul>

                        </div>

                        <div className="dashboard-item-inner3">
                           <div className="pdf-all-page-container">
                              <SimpleBarReact >
                                 <div style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'auto', backgroundColor: '#F2F2F2' }}>
                                    <div style={{ marginTop: '35px', marginBottom: '20px', width: '100%', maxWidth: '1000px', height: '100%', border: '1px solid #ccc', borderRadius: '7px', backgroundColor: '#FFF', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                                       <div className='block-container'>
                                          <div className="login-header align-left" style={{ margin: '35px 35px' }}>
                                             <h3><span>APPLICATION</span> FORM</h3>
                                             <p className="text-muted" style={{ fontSize: '18px' }}>Learner's Profile Form</p>
                                          </div>

                                          <div className='align-right' style={{ width: '165px', height: '165px', paddingTop: '35px', paddingRight: '35px' }}>
                                             <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                backgroundColor: '#FFFFFF',
                                                borderRadius: '7px',
                                                height: '125px',
                                                border: '2px solid rgba(30, 30, 30, 0.1)',
                                             }}>
                                                <Image src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${applicationInfo.picture}`} preview={false} height={'100%'} />
                                             </div>
                                          </div>
                                       </div>

                                       <Form
                                          layout='vertical'
                                          style={{ paddingLeft: '35px', paddingRight: '35px', paddingBottom: '35px' }}
                                       >

                                          <Divider orientation="left" style={{ paddingBottom: '20px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                                             <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Applying For</Title>
                                          </Divider>

                                          <Text strong>{applicationInfo.applying_for}</Text>

                                          {
                                             sectionedPIConfig !== null &&
                                             <>
                                                <SectionedFormRenderer sectionedFormConfig={sectionedPIConfig} form={form} />
                                                {
                                                   putFieldValues(applicationInfo)
                                                }
                                             </>

                                          }
                                          {
                                             user_role !== 'Guidance' &&
                                             <>
                                                <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                                                   <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Required Documents</Title>
                                                </Divider>

                                                <Row gutter={[12, 12]}>
                                                   <Col xs={24} sm={24} md={24} lg={24}>
                                                      <ul>
                                                         <li style={{ marginBottom: '5px' }}>
                                                            Birth Certificate (PSA Accredited) <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(bc)}><EyeOutlined /></Button>
                                                         </li>
                                                         <li>
                                                            Report Card or Diploma <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(rc)}><EyeOutlined /></Button>
                                                         </li>
                                                      </ul>
                                                   </Col>
                                                </Row>
                                             </>
                                          }
                                       </Form>
                                    </div>
                                 </div>
                              </SimpleBarReact>
                           </div>
                        </div>
                     </div>


                     <div className="notice-item" style={{ marginLeft: '15px', marginRight: '5px' }}>
                        <div className='answer-sheet-content'>
                           <SimpleBarReact>
                              {
                                 exam_mode === "online" &&
                                 <>
                                    <Divider orientation="left">
                                       <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Exam Result</Title>
                                    </Divider>
                                    <Space>
                                       <Tag color={"cyan"} style={{ padding: 7, fontSize: '16px' }}>{'Score: ' + applicationInfo.exam_score + ' of ' + applicationInfo.exam_total_points}</Tag>
                                       <Tag color={"cyan"} style={{ padding: 7, fontSize: '16px' }}>{'Status: ' + (applicationInfo.exam_status === 'exam_passed' ? 'PASSED' : 'FAILED')}</Tag>
                                    </Space>
                                 </>
                              }

                              <Divider orientation="left">
                                 <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Interview Notes</Title>
                              </Divider>
                              <TextArea
                                 allowClear={true}
                                 id={application_id}
                                 name={application_id}
                                 rows={8}
                                 onBlur={(event) => setNotes(event.target.value)}
                                 defaultValue={applicationInfo.interview_notes}
                                 disabled={mode.includes('edit') ? false : true}
                                 style={{ borderRadius: '7px' }}
                              />
                              <Divider orientation="left">
                                 <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Recommendations</Title>
                              </Divider>
                              <TextArea
                                 allowClear={true}
                                 id={application_id}
                                 name={application_id}
                                 rows={8}
                                 onBlur={(event) => setRecommendation(event.target.value)}
                                 defaultValue={applicationInfo.interview_recommendation}
                                 disabled={mode.includes('edit') ? false : true}
                                 style={{ borderRadius: '7px' }}
                              />
                              {
                                 (mode === 'edit' || mode === 'edit-view') &&
                                 <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                                    <Space>
                                       {/* <Popconfirm
                                          title="Do you want to approve this applicant?"
                                          onConfirm={() => handleSaveInterview('approved')}
                                          okText="Yes"
                                          cancelText="No"
                                       >
                                          <Button className='button-shadow' type='primary' shape='round'>Passed</Button>
                                       </Popconfirm>
                                       <Checkbox>With Condition</Checkbox>
                                       <Popconfirm
                                          title="Do you want to disapproved this applicant?"
                                          onConfirm={() => handleDisapprove()}
                                          okText="Yes"
                                          cancelText="No"
                                       >
                                          <Button className='button-shadow' type='primary' shape='round'>Failed</Button>
                                       </Popconfirm> */}
                                       <Popconfirm
                                          title="Do you want to set the interview status to passed?"
                                          onConfirm={() => handleSaveInterview('interview_passed')}
                                          okText="Yes"
                                          cancelText="No"
                                       >
                                          <Button className='button-shadow' type='primary' shape='round'>Passed</Button>
                                       </Popconfirm>

                                       <Popconfirm
                                          title="Do you want to set the interview status to passed with condition?"
                                          onConfirm={() => handleSaveInterview('interview_passed_with_condition')}
                                          okText="Yes"
                                          cancelText="No"
                                       >
                                          <Button className='button-shadow' type='primary' shape='round'>Passed with condition</Button>
                                       </Popconfirm>

                                       <Popconfirm
                                          title="Do you want to set the interview status to failed?"
                                          onConfirm={() => handleSaveInterview('interview_failed')}
                                          okText="Yes"
                                          cancelText="No"
                                       >
                                          <Button className='button-shadow' type='primary' shape='round'>Failed</Button>
                                       </Popconfirm>
                                    </Space>
                                 </div>
                              }
                           </SimpleBarReact>
                        </div>
                     </div>
                  </Split>
               </Spin>
            </div >
         }


         <Modal
            key='preview-doc'
            title={"Preview "}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            width='75%'

            onCancel={() => { setModalVisible(false); }}
            onOk={() => { setModalVisible(false); }}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               {renderPreviewer(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${docToView}`)}
            </div>
         </Modal>
      </>
   );
}