import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb, Select, Button, Row, Col, Typography, Modal, Upload, Image, Progress } from 'antd';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import axios from 'axios';
import DocumentViewer from '../../components/document-viewer/DocumentViewer';
import { CloudUploadOutlined, SendOutlined } from '@ant-design/icons';
import ProcessingModal from '../../components/processing/ProcessingModal';

const { Option } = Select;
const { Text } = Typography;

export function StudentFees(props) {

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [currentDocument, setCurrentDocument] = useState(null);
   const [selectedScheme, setSelectedScheme] = useState(null);
   const [paymentSchemes, setPaymentSchemes] = useState([]);
   // const [applicantID, setApplicantID] = useState(null);
   const [hasSelected, setHasSelected] = useState(false);
   const [processed, setProcessed] = useState(false);

   const [selectedSOF, setSelectedSOF] = useState(null);
   const [sof, setSOF] = useState([]);

   const [percentage, setPercentage] = useState(0);
   const [isUploading, setIsUploading] = useState(false);
   const [updating, setUpdating] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      GetSOF(user_id);
      // GetPaymentSchemes();
   }, [user_id]);

   // const GetPaymentSchemes = (id) => {
   //    axios.get(`${process.env.REACT_APP_API_CPH}/paymentschemes?id=${id}`).then((response) => {
   //       if (response.data) {
   //          const data = response.data;

   //          if (data !== null && data !== "") {
   //             setApplicantID(data[0].id);
   //             var ps = JSON.parse(data[0].tuition_assessment_terms);

   //             if (data[0].tuition_assessment_scheme !== null && data[0].tuition_assessment_scheme !== "") {
   //                // var result = ps.find(item => item.value = data[0].tuition_assessment_scheme); //-- Buggy
   //                let result;
   //                ps.forEach(item => {
   //                   if (item.value === data[0].tuition_assessment_scheme) {
   //                      result = item;
   //                   }
   //                });
   //                setCurrentDocument(result.pdf);
   //                setSelectedScheme(data[0].tuition_assessment_scheme);
   //                setHasSelected(true);
   //                setProcessed(data[0].application_status === "enrolled" && data[0].tuition_assessment_pop_valid === '1');
   //             }

   //             setPaymentSchemes(ps);
   //          }
   //       }
   //    }, (error) => {
   //       console.log(error.message);
   //    });
   // }

   const GetSOF = (id) => {
      axios.get(`${process.env.REACT_APP_API_CPH}/getsof?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            if (data !== null && data !== "") {
               if (data.length > 1)
                  setSOF(data);
               else {
                  setSelectedSOF(data[0].description);
                  setCurrentDocument(data[0].document);
               }
            }

            GetPaymentSchemes();
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const GetPaymentSchemes = () => {
      axios.get(`${process.env.REACT_APP_API_CPH}/v2/paymentschemes`).then((response) => {
         if (response.data) {
            const data = response.data;

            if (data !== null && data !== "") {
               setPaymentSchemes(data);
            }
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleChange = (val) => {
      if (val !== "" && val !== undefined) {
         setSelectedScheme(val);
      }
   }

   const handleClear = () => {
      setCurrentDocument(null);
      setSelectedScheme(null);
   }

   const handleSubmit = () => {
      setUpdating(true);
      var url = `${process.env.REACT_APP_API_CPH}/updateselectedfee?id=${user_id}&fv1=${selectedScheme}&fv2=${currentDocument}&fv3=${selectedSOF}`;

      axios.post(url).then((response) => {
         if (response.data) {

            setUpdating(false);

            Modal.success({
               title: 'Online Application',
               content: 'Submission successful.',
               centered: true,
               onOk: GetSOF(user_id),
            });
         }
      }, (error) => {
         setUpdating(false);

         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
      });
   }

   const upload_option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded * 100) / total)
         setPercentage(p)
      }
   }

   const handleUpload = (file) => {
      const formData = new FormData();
      formData.append('file', file);

      setPercentage(0);
      setIsUploading(true);

      var upload_url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMasteryFile?s3path=${process.env.REACT_APP_S3_ONLINE_APPLICATION_PATH}/assessment-pop`;
      axios.post(upload_url, formData, upload_option).then(response => {
         console.log(response);
         if (response.data) {
            let data = response.data;

            var url = `${process.env.REACT_APP_API_CPH}/updatesafield?id=${user_id}&fn=tuition_assessment_pop&fv=${data}`;

            axios.post(url).then((response) => {
               if (response.data) {
                  Modal.success({
                     title: 'Online Application',
                     content: 'Upload successfull.',
                     centered: true,
                  });

                  setIsUploading(false);
               }
            }, (error) => {
               setIsUploading(false);
               Modal.error({
                  title: 'Online Application',
                  content: error.response.data,
                  centered: true
               });
            });
         }
      }, (error) => {
         console.log(error.message);
         setIsUploading(false);
         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
      });
   }

   const UploadButton = () => {
      const props = {
         beforeUpload: file => {
            const isPNG = file.type === 'image/png';
            const isJPG = file.type === 'image/jpeg';
            const isBMP = file.type === 'image/bmp';
            const isPDF = file.type === 'application/pdf';

            const fileValid = isPNG || isJPG || isBMP || isPDF;

            if (fileValid) {
               const isLt100M = file.size / 1024 / 1024 <= 5;

               if (isLt100M) {
                  handleUpload(file);
               } else {
                  Modal.error({
                     title: 'Schedule',
                     content: 'File should be less than 5 MB',
                     centered: true
                  });
               }
            } else {
               Modal.error({
                  title: 'Schedule',
                  content: 'You are not allowed to upload that file type',
                  centered: true
               });
            }
         },
      };
      return (
         <Upload {...props} showUploadList={false}
            accept=".png,.jpg,.pdf,.bmp"
         >
            <Button className='button-shadow' type="primary" size="middle" shape='round'>
               <Text ellipsis style={{ color: "#fff" }}><CloudUploadOutlined />&nbsp;Upload Proof of Payment</Text>
            </Button>
         </Upload>
      );
   };

   const handleSOFChange = (val) => {
      let _sof = [...sof];
      let result;
      _sof.forEach(item => {
         if (item.description === val) {
            result = item;
         }
      });

      setCurrentDocument(result.document);
      setSelectedSOF(result.description);
   }

   return (
      <>
         <ProcessingModal
            processing={updating}
            title={'Brainee LMS'}
         />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        Assessment Fees
                     </Breadcrumb.Item>
                  </Breadcrumb>
               </div>
               <div style={{ display: "flex", alignItems: "center", justifyContent: "left", paddingBottom: "10px" }}>
                  {
                     sof.length > 1 &&
                     <div>
                        <Text>Schedule of Fees</Text>&nbsp;&nbsp;
                        <Select
                           key={'uid' + user_id}
                           showArrow
                           disabled={processed}
                           size='middle'
                           style={{ minWidth: "300px" }}
                           allowClear
                           onChange={(val) => handleSOFChange(val)}
                           onClear={() => handleClear()}
                        // defaultValue={selectedScheme}
                        >
                           {sof.map(({ id, document, description }) => {
                              return <Option value={description}>{description}</Option>
                           })}
                        </Select>&nbsp;&nbsp;
                     </div>
                  }
                  {
                     paymentSchemes.length > 0 &&
                     <div>
                        <Text>Select Payment Terms</Text>&nbsp;&nbsp;
                        <Select
                           key={'uid' + user_id}
                           showArrow
                           disabled={processed}
                           size='middle'
                           style={{ minWidth: "200px" }}
                           allowClear
                           onChange={(val) => handleChange(val)}
                           onClear={() => handleClear()}
                           defaultValue={selectedScheme}
                        >
                           {paymentSchemes.map(({ scheme }) => {
                              return <Option value={scheme}>{scheme}</Option>
                           })}
                        </Select>&nbsp;&nbsp;
                        <Button type='primary' shape='round' disabled={processed} onClick={() => handleSubmit()}><SendOutlined />{hasSelected ? "Re-submit" : "Submit"}</Button>&nbsp;&nbsp;
                        {UploadButton()}
                     </div>
                  }
               </div>
               <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                  <div style={{ width: "80%" }}>
                     <DocumentViewer
                        document={currentDocument}
                        documentlist={null}
                        dvheight={"calc(100vh - 100px)"}
                        emptydescription={'Assessment Fees not yet available'}
                     />
                  </div>
               </div>
            </div>
         </div>

         <Modal
            key="modal-upload"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Upload File</div>}
            destroyOnClose={true}
            centered
            open={isUploading}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <span>Uploading...</span>
            <Progress
               percent={percentage}
               status="active"
               strokeColor={{
                  '0%': '#5A98D2',
                  '100%': '#70D0D4',
               }}
            />
         </Modal>
      </>
   );
}