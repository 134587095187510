/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Image, Radio, Form, Divider, Row, Col, Typography, Input, DatePicker, Space, Button, Modal, Popconfirm } from 'antd';
import { EyeOutlined, DownOutlined } from '@ant-design/icons';
import Utils from '../../misc/Utils';
// import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import "./Application.scss"
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import moment from 'moment';
import SectionedFormRenderer from '../../components/utility/SectionedFormRenderer';

const { Title, Text } = Typography;

export function ReviewApplication() {
   const [form] = Form.useForm();
   const navigate = useNavigate();
   const location = useLocation();

   const subdomain = localStorage.getItem("lms-subdomain");

   const search = window.location.search;
   const query = new URLSearchParams(search);
   const application_id = query.get('id');
   // const application_status = query.get('status');
   const application_status = location.state.status;

   const [applicationInfo, setApplicationInfo] = useState(null);
   const [applicationOtherInfo, setApplicationOtherInfo] = useState(null);
   const [rc, setRC] = useState(null);
   const [bc, setBC] = useState(null);
   const [modalVisible, setModalVisible] = useState(false);
   const [docToView, setDocToView] = useState(null);
   const [sectionedPIConfig, setSectionedPIConfig] = useState(null);

   useEffect(() => {
      Utils.verifyJwt();

      getApplicationInfo(application_id);
   }, []);

   const getFormSectionedFields = (fn) => {
      var url = `${process.env.REACT_APP_API_CPH}/getsectionedfields?fn=${fn}&ft=basic&m=view`;
      /*console.log(url);*/
      axios.get(url)
         .then((response) => {
            if (response.data) {
               var data = response.data;
               setSectionedPIConfig(data);
               //console.log(data);
            }
         })
         .catch((error) => {
            // setLoading(false);
            console.log(error.message);
         });
   };

   const getApplicationInfo = (id) => {
      var url = `${process.env.REACT_APP_API_CPH}/getapplicationinfo?id=${id}`;

      axios.get(url).then((response) => {
         if (response.data) {
            let data = response.data;
            let docs = JSON.parse(data.documents);

            setBC(docs.birth_certificate);
            setRC(docs.report_card);
            setApplicationOtherInfo(JSON.parse(data.other_info));
            setApplicationInfo(data);

            getFormSectionedFields(data.form_number);
         }
      }, (error) => {
         // setLoading(false);
         console.log(error.message);
      });
   }

   const putFieldValues = (data) => {
      var fieldValues = JSON.parse(data.other_info);

      try {
         for (const sectionKey in sectionedPIConfig) {
            const section = sectionedPIConfig[sectionKey];
            for (const fieldKey in section.fields) {
               if (fieldKey.toLocaleLowerCase().includes("date")) {
                  form.setFieldValue(fieldKey, moment(fieldValues[fieldKey], 'YYYY-MM-DD'));
               }
               else {
                  form.setFieldValue(fieldKey, fieldValues[fieldKey]);
               }
            }
         }
      } catch (error) {
         console.error(error);
      }


   }

   const renderPreviewer = (document) => {
      return <>
         <div
            className={'modal-document-viewer'}
            style={{ height: "calc(100vh - 195px)" }}
         >
            <Image
               // src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${document}`}
               src={document}
               style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
            />
         </div>
      </>
   }

   const handleViewDoc = (doctoview) => {
      setDocToView(doctoview);
      setModalVisible(true);
   }

   const handleUpdateStatus = (newstatus) => {
      var url = `${process.env.REACT_APP_API_CPH}/updateapplicationstatus?id=${application_id}&status=${newstatus}`;
      axios.post(url).then((response) => {
         if (response.data) {

            Modal.success({
               title: 'Online Application',
               content: 'Application was successfuly rejected.',
               centered: true,
               onOk: navigate('/applicantlist')
            });
         }
      }, (error) => {
         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleAcceptApplication = () => {
      var url = `${process.env.REACT_APP_API_CPH}/acceptapplication?id=${application_id}`;
      axios.post(url).then((response) => {
         if (response.data) {
            Modal.success({
               title: 'Online Application',
               content: 'Application was successfuly accepted.',
               centered: true,
               onOk: navigate('/applicantlist')
            });
         }
      }, (error) => {
         Modal.error({
            title: 'Online Application',
            content: error.response.data,
            centered: true
         });
         console.log(error.message);
      });
   }

   // const _downloadFile = (url, name) => {
   //    // setDownloadStatus(true);

   //    var re = new RegExp(' ', 'g');
   //    var file_name = name.replace(re, '_');
   //    var pos = url.lastIndexOf('.');
   //    var ext = url.slice(pos);

   //    fetch(url)
   //       .then(response => {
   //          response.blob().then(blob => {
   //             let url = window.URL.createObjectURL(blob);
   //             let a = document.createElement('a');
   //             a.href = url;
   //             a.download = file_name + ext;
   //             a.click();
   //             // setDownloadStatus(false);
   //          });
   //       });
   //    /*setDownloadStatus(true)
   //       FileSaver.saveAs(currentS3ContentLink, downloadOfficeFileName)
   //    setDownloadStatus(false) */
   // };

   return (
      <>
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <ul>
                     <li className="active"><a href="#" onClick={() => navigate('/applicantlist')}>Student Applicants</a></li>
                     <li><span>/</span></li>
                     {
                        application_status === 'pending'
                           ?
                           <li className="active">Review Application</li>
                           :
                           <li className="active">Applicant Information</li>
                     }

                  </ul>

               </div>

               <div className="dashboard-item-inner3">
                  {
                     (applicationInfo !== null && applicationOtherInfo !== null) &&
                     <div style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'auto', backgroundColor: '#F2F2F2' }}>
                        <div style={{ marginTop: '35px', marginBottom: '20px', width: '100%', maxWidth: '1000px', height: '100%', border: '1px solid #ccc', borderRadius: '7px', backgroundColor: '#FFF', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                           <div className='block-container'>
                              <div className="login-header align-left" style={{ margin: '35px 35px' }}>
                                 <h3><span>APPLICATION</span> FORM</h3>
                                 <p className="text-muted" style={{ fontSize: '18px' }}>Learner's Profile Form</p>
                              </div>

                              <div className='align-right' style={{ width: '165px', height: '165px', paddingTop: '35px', paddingRight: '35px' }}>
                                 <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '7px',
                                    height: '125px',
                                    border: '2px solid rgba(30, 30, 30, 0.1)',
                                 }}>
                                    <Image src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${applicationInfo.picture}`} preview={false} height={'125px'} />
                                 </div>
                              </div>
                           </div>

                           <Form
                              layout='vertical'
                              style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}
                           >
                              <Divider orientation="left" style={{ paddingBottom: '20px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                                 <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Applying For</Title>
                              </Divider>

                              <Typography.Text strong>{applicationInfo.applying_for}</Typography.Text>

                              {
                                 sectionedPIConfig !== null &&
                                 <>
                                    <SectionedFormRenderer sectionedFormConfig={sectionedPIConfig} form={form} />
                                    {
                                       putFieldValues(applicationInfo)
                                    }
                                 </>

                              }

                              <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                                 <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Required Documents</Title>
                              </Divider>

                              <Row gutter={[12, 12]}>
                                 <Col xs={24} sm={24} md={24} lg={24}>
                                    {/* <ul> */}
                                    <li style={{ marginBottom: '5px' }}>
                                       Birth Certificate (PSA Accredited) <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(bc)}><EyeOutlined /></Button>
                                    </li>
                                    <li>
                                       Report Card or Diploma <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(rc)}><EyeOutlined /></Button>
                                    </li>
                                    {/* </ul> */}
                                 </Col>
                              </Row>
                              {
                                 application_status === 'pending'
                                    ?
                                    <Row gutter={[12, 12]} style={{ padding: '15px 0px 0px 0px' }}>
                                       <Col span={24} style={{ display: 'flex', justifyContent: 'right' }}>
                                          <Space>
                                             <Popconfirm
                                                title="Are you sure you want to accept this application?"
                                                onConfirm={() => handleAcceptApplication()}
                                                okText="Yes"
                                                cancelText="No"
                                             >
                                                <Button className='button-shadow' type='primary' shape='round'>Accept</Button>
                                             </Popconfirm>
                                             <Popconfirm
                                                title="Are you sure you want to reject this application?"
                                                onConfirm={() => handleUpdateStatus('rejected')}
                                                okText="Yes"
                                                cancelText="No"
                                             >
                                                <Button className='button-shadow' type='primary' shape='round'>Reject</Button>
                                             </Popconfirm>
                                          </Space>
                                       </Col>
                                    </Row>
                                    :
                                    <Row style={{ paddingBottom: "10px" }}><Col>&nbsp;</Col></Row>
                              }
                           </Form>
                        </div>
                     </div>
                  }

               </div>
            </div>
         </div>

         <Modal
            key='preview-doc'
            title={"Preview "}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            width='75%'

            onCancel={() => { setModalVisible(false); }}
            onOk={() => { setModalVisible(false); }}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               {renderPreviewer(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${docToView}`)}
            </div>
         </Modal>
      </>
   );
}